import {
  LoadingOutlined,
  ReloadOutlined,
  SendOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";

import Title from "antd/es/typography/Title";
import Api from "../../../apis/Api";
import TokenResetApi from "../../../apis/TokenResetApi";
import {
  disableViewTutorial,
  getParamFromUrl,
  getTutorialCode,
  isNullOrEmpty,
  showNotification,
} from "../../../common";
import FiledNameFile, {
  getFiledByType,
  getKeyByFiled,
} from "../../../common/FiledNameFile";
import Prefixs from "../../../common/Prefixs";
import ResetStatus from "../../../common/ResetStatus";
import FailOtp from "../../otp/FailOtp";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import NotFoundInfo from "./NotFoundInfo";
import { Helmet } from "react-helmet";
import Titles from "../../../common/Titles";
import Footer from "../../footer";
import GloabalConst from "../../../common/GloabalConst";
import TutorialCode from "../../../common/TutorialCode";

const ResetPin = () => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [fileAdditionals, setFileAdditionals] = useState({
    [FiledNameFile.TC_GTPL.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFile.TC_CMND_NDD.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFile.CN_TC_XACNHANTOCHUC.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFile.CN_TC_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFile.CN_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
  });

  const [data, setData] = useState({});
  const [isAuth, setIsAuth] = useState(true);
  const [isNotFoundInfo, setIsNotFoundInfo] = useState(false);

  const [tokenProgramResponse, setTokenProgramResponse] = useState(null);

  const [form] = Form.useForm();

  const [lastAttemptChecked, setLastAttemptChecked] = useState(false);

  useEffect(() => {
    getTutorialCode();
    const dataParam = getParamFromUrl("data");
    if (!isNullOrEmpty(dataParam)) {
      auth(dataParam);
    } else {
      showNotification("error", "Thông báo");
    }
    const urlCurrent = window.location.href;
    setUrl(urlCurrent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isAuth) {
      handleTutorialCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  const onFinish = async (values) => {
    setLoading(true);
    let fileRequest = [];
    for (const key in FiledNameFile) {
      if (FiledNameFile.hasOwnProperty(key)) {
        const enumItem = FiledNameFile[key];
        if (!isNullOrEmpty(fileAdditionals[enumItem.filed]?.data)) {
          // Case khi người dùng tải lại file mới
          getFileUpdate(fileRequest, enumItem.filed);
        } else if (!isNullOrEmpty(fileAdditionals[enumItem.filed]?.fileData)) {
          // Case khi người dùng không tải lại file và gửi luôn
          getFile(fileRequest, enumItem.filed);
        }
      }
    }

    let request = {
      ...values,
      files: fileRequest,
      type: data.type,
    };
    if (isAuth) {
      handleRequest(TokenResetApi.verification, 201, request);
    } else {
      if (
        data &&
        data.status &&
        data.status === ResetStatus.REJECT &&
        data.id
      ) {
        handleRequest(TokenResetApi.putUnVerification, 200, data.id, request);
      } else {
        handleRequest(TokenResetApi.postUnVerification, 201, request);
      }
    }
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      if (res && res.status === status && res.data.data) {
        setIsFinish(true);
        setData(res.data.data);
        if (res.data.data.details) {
          handleLockTokenDetails(res.data.data.details);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const getFile = (fileRequest, filed) => {
    fileRequest.push({
      id: fileAdditionals[filed].id,
      fileName: fileAdditionals[filed].fileName,
      type: fileAdditionals[filed].type,
      data: fileAdditionals[filed].fileData,
    });
  };

  const getFileUpdate = (fileRequest, filed) => {
    fileRequest.push({
      id: fileAdditionals[filed].id,
      fileName: fileAdditionals[filed].fileName,
      type: getKeyByFiled(fileAdditionals[filed].type),
      data: fileAdditionals[filed].data,
    });
  };

  const handleTutorialCode = () => {
    localStorage.setItem(
      GloabalConst.TUTORIAL_CODE,
      TutorialCode.RESET_PIN_NOTAUTHENTICATED.value
    );
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const resDetail = await TokenResetApi.detail();
      if (resDetail && resDetail.status === 200 && resDetail.data.data) {
        if (
          resDetail.data.data.resetPinResponse.name &&
          resDetail.data.data.resetPinResponse.phone &&
          resDetail.data.data.resetPinResponse.email
        ) {
          if (
            resDetail.data.data.resetPinResponse.status ===
              ResetStatus.SUPPORT ||
            resDetail.data.data.resetPinResponse.status === ResetStatus.REJECT
          ) {
            setIsAuth(false);
          }
        }
        if (
          resDetail.data.data.resetPinResponse.status === ResetStatus.WAIT_RESET
        ) {
          disableViewTutorial();
        }

        setData(resDetail.data.data.resetPinResponse);
        handleDataResponse(resDetail.data.data.resetPinResponse);
        handleDataOtpResponse(resDetail.data.data);
      }
      form.setFieldsValue({
        otp: null,
      });
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
      if (error.response && error.response.status === 404) {
        setIsNotFoundInfo(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDataOtpResponse = (data) => {
    const tokenOTPResponse = data.tokenOTPResponse;
    if (tokenOTPResponse) {
      setTokenProgramResponse(tokenOTPResponse);
      if (
        !isNullOrEmpty(tokenOTPResponse.timeOTPRemaining) &&
        tokenOTPResponse.timeOTPRemaining === 0
      ) {
        setLastAttemptChecked(true);
      }
    }
  };

  const auth = async (data) => {
    setLoading(true);
    Api.auth(data)
      .then((res) => {
        if (res && res.status === 200) {
          sessionStorage.setItem("token", res.data.data.jwt);
          fetchDataDetail();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        showNotification("error", error.response.data.message, "Thông báo");
        setLoading(false);
      });
  };

  const handleDataResponse = (data) => {
    if (data.details && data.details.length > 0) {
      handleLockTokenDetails(data.details);
    }
    form.setFieldsValue({
      name: data.name,
      phone: data.phone,
      email: data.email,
    });
    setIsFinish(data.status === ResetStatus.SUPPORT);
  };

  const handleLockTokenDetails = (details) => {
    let newFileAdditionals = {};
    details.forEach((item) => {
      const type = getFiledByType(item.type);
      newFileAdditionals[type] = {
        id: item.id,
        fileName: item.fileName,
        type: item.type,
        data: item.data,
        fileId: item.fileId,
        fileData: item.fileData,
      };
      if (
        type === FiledNameFile.TC_GTPL.filed ||
        type === FiledNameFile.CN_TC_XACNHANTOCHUC.filed
      ) {
        form.setFieldsValue({
          dkkd: item.fileName,
        });
      }
      if (
        type === FiledNameFile.CN_CMND.filed ||
        type === FiledNameFile.TC_CMND_NDD.filed ||
        type === FiledNameFile.CN_TC_CMND.filed
      ) {
        form.setFieldsValue({
          cmnd: item.fileName,
        });
      }
    });
    setFileAdditionals(newFileAdditionals);
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.RESET_PIN_AUTHENTICATED.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="reset-pin-container"
      >
        <Header
          url={url}
          data={data}
          tokenProgramResponse={tokenProgramResponse}
          isNotFoundInfo={isNotFoundInfo}
          lastAttemptChecked={lastAttemptChecked}
        ></Header>
        {data?.status === ResetStatus.WAIT_RESET ? (
          <Row>
            <span className="title-reset-pin-succes">
              Reset mã PIN thành công
            </span>
            <Title level={5} className="noti-reset-pin-succes">
              Quý khách vui lòng{" "}
              <span className="noti-reset-pin-succes-hig">
                rút Token và cắm lại
              </span>{" "}
              để tiếp tục sử dụng dịch vụ.
            </Title>
          </Row>
        ) : (
          <>
            {lastAttemptChecked ? (
              <FailOtp />
            ) : (
              <>
                {isNotFoundInfo ? (
                  <NotFoundInfo />
                ) : (
                  <Form
                    onFinish={onFinish}
                    layout="vertical"
                    className="reset-pin-form"
                    form={form}
                  >
                    {data.status === ResetStatus.REJECT && (
                      <span className="text-is-finish">
                        Hồ sơ của Quý khách bị từ chối do [{data.reasonRefusal}
                        ]. Vui lòng chỉnh sửa và gửi lại.
                      </span>
                    )}

                    <ContactInfo
                      url={url}
                      data={data}
                      isFinish={isFinish}
                      setLoading={setLoading}
                      form={form}
                      tokenProgramResponse={tokenProgramResponse}
                      setTokenProgramResponse={setTokenProgramResponse}
                      setFileAdditionals={setFileAdditionals}
                      fileAdditionals={fileAdditionals}
                      isAuth={isAuth}
                      setIsAuth={setIsAuth}
                      lastAttemptChecked={lastAttemptChecked}
                      setLastAttemptChecked={setLastAttemptChecked}
                    />
                    {isFinish ? (
                      <>
                        {url.includes(Prefixs.RESET_PIN_AUTHENTICATED) && (
                          <span className="text-is-finish">
                            Thông tin của Quý khách đã được gửi đến nhà cung
                            cấp. Vui lòng chờ nhà cung cấp kiểm tra và xác minh
                            thông tin.
                          </span>
                        )}
                        <Form.Item>
                          <Row justify="center">
                            <Col>
                              <Button
                                icon={<ReloadOutlined />}
                                type="primary"
                                onClick={fetchDataDetail}
                              >
                                Tải lại trang
                              </Button>
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                    ) : (
                      <>
                        {isAuth ? (
                          <Form.Item>
                            <Row justify="center" gutter={16}>
                              <Col>
                                <Button
                                  icon={<SendOutlined />}
                                  type="primary"
                                  htmlType="submit"
                                >
                                  Reset mã PIN
                                </Button>
                              </Col>
                              {/* <Col>
                                <Button
                                  icon={<EditOutlined />}
                                  type="primary"
                                  onClick={() => {
                                    setIsAuth(false);
                                  }}
                                >
                                  Thay đổi thông tin
                                </Button>
                              </Col> */}
                            </Row>
                          </Form.Item>
                        ) : (
                          <Form.Item>
                            <Row justify="center">
                              <Col>
                                <Button
                                  icon={<SendOutlined />}
                                  type="primary"
                                  htmlType="submit"
                                >
                                  Gửi thông tin
                                </Button>
                              </Col>
                            </Row>
                          </Form.Item>
                        )}
                      </>
                    )}
                  </Form>
                )}
              </>
            )}
          </>
        )}

        {/* <Row className="reset-pin-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
    </Spin>
  );
};

export default ResetPin;
