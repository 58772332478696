import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Form, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import SignApi from "../../../../apis/SignApi";
import SupportApi from "../../../../apis/SupportApi";
import {
  disableViewTutorial,
  getParamFromUrl,
  getTutorialCode,
  isNullOrEmpty,
  showNotification,
} from "../../../../common";
import FiledNameFileChangeInfo from "../../../../common/FiledNameFileChangeInfo";
import TypeFromLockCer from "../../../../common/TypeFromLockCer";
import ModalPromotion from "../../../ModalPromotion";
import ConfirmChangeInfo from "./ConfirmChangeInfo";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import InfoOrg from "./InfoOrg";
import InfoPerson from "./InfoPerson";
import InfoPersonOrg from "./InfoPersonOrg";
import "./index.css";
import { Helmet } from "react-helmet";
import Titles from "../../../../common/Titles";
import Footer from "../../../footer";

const ChangeInfo = () => {
  const [loading, setLoading] = useState(false);
  const [isOpenModalProm, setIsOpenModalProm] = useState(false);
  const [linkPromotion, setLinkPromotion] = useState("");
  const [isViewPdfSign, setIsViewPdfSign] = useState(false);
  const [isFininsh, setIsFininsh] = useState(false);
  const [fileAdditionals, setFileAdditionals] = useState({
    [FiledNameFileChangeInfo.TC_GTPL.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFileChangeInfo.TC_CMND_NDD.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFileChangeInfo.CN_TC_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFileChangeInfo.CN_TC_XACNHANTOCHUC.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFileChangeInfo.CN_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
  });
  const [data, setData] = useState([]);
  const [tokenProgramResponse, setTokenProgramResponse] = useState({});
  const [resultSave, setResultSave] = useState({});
  const [base64FileChange, setBase64FileChange] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getTutorialCode();
    fetchDataDetail();
    const popupParam = getParamFromUrl("openPopup");
    if (!isNullOrEmpty(popupParam)) {
      setIsOpenModalProm(true);
      setLinkPromotion(popupParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFininsh) {
      disableViewTutorial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFininsh]);

  const onFinish = async (values) => {
    setIsViewPdfSign(true);
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      console.log("res: ", res);
      if (res && res.status === status && res.data.data) {
        setResultSave(res.data.data);
        if (res.data.data.id) {
          generateFileChangeInfo(res.data.data.id);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const generateFileChangeInfo = async (id) => {
    try {
      const res = await SupportApi.generateFileChangeInfo(id);
      console.log("res: ", res);
      if (res && res.status === 200 && res.data.data) {
        setBase64FileChange(res.data.data);
        setIsViewPdfSign(true);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const res = await SupportApi.detail();
      if (res && res.status === 200 && res.data.data && res.data.data.data) {
        setData(res.data.data.data);
        form.setFieldsValue({
          fullName: res.data.data.data.name,
          contactName: res.data.data.data.name,
          contactEmail: res.data.data.data.email,
          contactMobile: res.data.data.data.phone,
          email: res.data.data.data.email,
          code: res.data.data.data.code,
          identityType: res.data.data.data.identityType,
          representFullName: res.data.data.data.representFullName,
          provinceCode: res.data.data.data.provinceCode,
          districtCode: res.data.data.data.districtCode,
          address: res.data.data.data.address,
          orCompany: res.data.data.data.orCompany,
          orDepartment: res.data.data.data.orDepartment,
          orPosition: res.data.data.data.orPosition,
        });
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const checkSetupPlugin = async () => {
    setLoading(true);
    try {
      const resCheckVersion = await SignApi.checkVersion();
      if (
        resCheckVersion &&
        resCheckVersion.status === 200 &&
        resCheckVersion.data
      ) {
        return true;
      } else {
        showNotification(
          "error",
          "Vui lòng cài đặt hoặc bật phần mềm ký",
          "Thông báo"
        );
        return false;
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
      return false;
    } finally {
      setLoading(false);
    }
  };

  const signPDF = async () => {
    setLoading(true);
    try {
      if (await checkSetupPlugin()) {
        const currentDate = moment();
        const formattedDate = currentDate.format("DD-MM-YYYY HH:mm:ss");
        let infoSign = {
          fileData: base64FileChange,
          certSerial: resultSave.serial,
          signDate: formattedDate,
          fieldName: "SignatureB [002]",
          typeSign: "1",
          page: "",
          px: "",
          py: "",
          pw: "",
          ph: "",
        };
        const res = await SignApi.signPDF(infoSign);
        if (res && res.status === 200 && res.data.FileDataSigned) {
          uploadSignedFile(resultSave.id, res.data.FileDataSigned);
        } else {
          showNotification("error", res.data.ErrorDetail, "Thông báo");
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const uploadSignedFile = async (id, data) => {
    setLoading(true);
    try {
      const request = { data: data };
      const res = await SupportApi.uploadSignedFile(id, request);
      console.log("res: ", res);
      if (res && res.status === 200) {
        setIsFininsh(true);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.SUPPORT_CHANGE_INFO.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="change-info-container"
      >
        <Header
          data={data}
          tokenProgramResponse={tokenProgramResponse}
          isFininsh={isFininsh}
        ></Header>
        {isFininsh ? (
          <>
            <Row>
              <span className="title-changeinfo-succes">
                Yêu cầu điều chỉnh thông tin đã gửi thành công
              </span>
            </Row>
            <Row>
              <span level={5} className="noti-changeinfo-succes">
                Yêu cầu điều chỉnh thông tin của Quý khách đã được gửi đến
                FastCA, chờ kiểm tra và xác minh thông tin.
              </span>
            </Row>
            <Row>
              <span level={5} className="noti-changeinfo-succes">
                Quý khách vui lòng kiểm tra Email để nhận kết quả xử lý yêu cầu
              </span>
            </Row>
          </>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            className="change-info-form"
            form={form}
          >
            {!isViewPdfSign ? (
              <>
                {data && data.type === TypeFromLockCer.TO_CHUC && (
                  <InfoOrg
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                    form={form}
                    tokenProgramResponse={tokenProgramResponse}
                    setTokenProgramResponse={setTokenProgramResponse}
                    setFileAdditionals={setFileAdditionals}
                    fileAdditionals={fileAdditionals}
                  />
                )}
                {data &&
                  data.type === TypeFromLockCer.CA_NHAN_THUOC_TO_CHUC && (
                    <InfoPersonOrg
                      data={data}
                      setData={setData}
                      setLoading={setLoading}
                      form={form}
                      tokenProgramResponse={tokenProgramResponse}
                      setTokenProgramResponse={setTokenProgramResponse}
                      setFileAdditionals={setFileAdditionals}
                      fileAdditionals={fileAdditionals}
                    />
                  )}
                {data && data.type === TypeFromLockCer.CA_NHAN && (
                  <InfoPerson
                    data={data}
                    setData={setData}
                    setLoading={setLoading}
                    form={form}
                    tokenProgramResponse={tokenProgramResponse}
                    setTokenProgramResponse={setTokenProgramResponse}
                    setFileAdditionals={setFileAdditionals}
                    fileAdditionals={fileAdditionals}
                  />
                )}
                <ContactInfo
                  data={data}
                  setLoading={setLoading}
                  form={form}
                  tokenProgramResponse={tokenProgramResponse}
                  setTokenProgramResponse={setTokenProgramResponse}
                  setFileAdditionals={setFileAdditionals}
                  fileAdditionals={fileAdditionals}
                />

                <Row justify="center">
                  <Form.Item>
                    <Button
                      icon={<SendOutlined />}
                      type="primary"
                      htmlType="submit"
                    >
                      Ký file và gửi yêu cầu
                    </Button>
                  </Form.Item>
                </Row>
              </>
            ) : (
              <>
                <ConfirmChangeInfo
                  base64FileChange={base64FileChange}
                ></ConfirmChangeInfo>
                <Row justify="center">
                  <Form.Item>
                    <Button
                      icon={<ArrowLeftOutlined />}
                      onClick={() => setIsViewPdfSign(false)}
                      className="btn-back"
                    >
                      Quay lại
                    </Button>
                    <Button
                      icon={<SendOutlined />}
                      type="primary"
                      onClick={signPDF}
                    >
                      Xác nhận ký
                    </Button>
                  </Form.Item>
                </Row>
              </>
            )}
          </Form>
        )}

        {/* <Row className="change-info-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
      {isOpenModalProm ? (
        <ModalPromotion
          setModalOpen={setIsOpenModalProm}
          isModalOpen={isOpenModalProm}
          link={linkPromotion}
        ></ModalPromotion>
      ) : (
        ""
      )}
    </Spin>
  );
};

export default ChangeInfo;
