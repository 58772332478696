import { notification } from "antd";
import constants from "../constants";
import GloabalConst from "./GloabalConst";
import { getTutorialCodeByPrefix } from "./TutorialCode";
import ViewTutorialType from "./ViewTutorialType";

export const showNotification = (type, description = "", message) => {
  if (message === undefined || message === null) {
    switch (type) {
      case "success": {
        message = "Thành công";
        break;
      }
      case "warn": {
        message = "Cảnh báo";
        break;
      }
      case "error": {
        message = "Lỗi";
        break;
      }
      default:
        message = type;
    }
  }
  notification[type]({
    message: message,
    description: description,
    duration: type === "error" ? 10 : 3,
  });
};

export const isNullOrEmpty = (value) => {
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    (typeof value === "string" && value.trim() === "")
  ) {
    return true;
  }
  return false;
};

export const getParamFromUrl = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const dowloadFile = (url, nameFileDowload) => {
  const link = document.createElement("dowload");
  link.href = url;
  link.download = nameFileDowload;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFormartFile = (fileName) => {
  const format = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  if (format === "jpg") {
    return "data:image/jpeg";
  } else if (format === "png") {
    return "data:image/png";
  } else if (format === "pdf") {
    return "data:application/pdf";
  }
};
export const validatePhoneNumber = (_, value) => {
  if (!value || constants.PHONE_REGEX.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    "Số điện thoại không đúng định dạng. Vui lòng nhập lại!"
  );
};

export const validateEmail = (_, value) => {
  if (!value || constants.EMAIL_REGEX.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject("Email không đúng định dạng. Vui lòng nhập lại!");
};

export const getTutorialCode = () => {
  localStorage.setItem(
    GloabalConst.TUTORIAL_CODE,
    getTutorialCodeByPrefix(window.location.pathname)
  );
};

export const disableViewTutorial = () => {
  localStorage.setItem(GloabalConst.VIEW_TUTORIAL, ViewTutorialType.DISABLE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
};
