import { Row } from "antd";
import React from "react";
import "../lockcertificate/index.css";
import logo from "../../assets/img/logo2.png";
import unlock from "../../assets/img/unlock.png";
import Title from "antd/es/typography/Title";
import "./index.css";
import Titles from "../../common/Titles";
import { Helmet } from "react-helmet";
import Footer from "../footer";

const Unlock = () => {
  return (
    <>
      <Helmet>
        <title>{Titles.UNLOCK_SUCCSES.value}</title>
      </Helmet>
      <Row justify="center" align="middle" className="unlock-container">
        <Row className="unlock-header">
          <img src={logo} className="logo" alt="logo" />
          <span className="title-noti-unlock-succses">Mở khóa thành công</span>
          <Title level={5} className="title-des-unlock-succses">
            Thiết bị USB Token của Quý khách đã được mở khóa. Quý khách có thể
            tiếp tục sử dụng dịch vụ.
          </Title>
        </Row>
        <Row className="unlock-body">
          <img src={unlock} className="unlock-img" alt="unlock-img" />
        </Row>
        {/* <Row className="unlock-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
    </>
  );
};

export default Unlock;
