import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../apis/Api";
import config from "../apis/EndpointConfig";
import { getParamFromUrl, isNullOrEmpty, showNotification } from "../common";
import ActionTypes from "../common/ActionTypes";

const GatewayCompoment = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const dataParam = getParamFromUrl("data");
    if (!isNullOrEmpty(dataParam)) {
      fetchData(dataParam);
    } else {
      showNotification("error", "Thông báo");
    }    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);

  const fetchData = async (data) => {
    setLoading(true);
    Api.getPrefixRouter(data)
      .then((res) => {
        console.log("res: ", res);
        if (res && res.status === 200 && res.data.data) {
          sessionStorage.setItem("token", res.data.data.jwt);
          if (
            res.data.data.type === ActionTypes.REDIRECT &&
            res.data.data.linkPromotion
          ) {
            window.location.href = res.data.data.linkPromotion;
          } else if (
            res.data.data.type === ActionTypes.ROUTER &&
            res.data.data.linkPromotion
          ) {
            let prefixRouter = res.data.data.linkPromotion;
            if (!isNullOrEmpty(res.data.data.linkPopup)) {
              prefixRouter += "?openPopup=" + res.data.data.linkPopup;
            }
            history.push(prefixRouter);
          } else {
            window.location.href = res.data.data.linkDefault;
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        window.location.href = config.HOST_PROMOTION_DEFAULT;
        setLoading(false);
      });
  };
  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <div></div>;
    </Spin>
  );
};

export default GatewayCompoment;
