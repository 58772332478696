// src/services/apiService.js
import fetch from "./FetchApiUtils";
import config from "./EndpointConfig";

const NotificationApi = {
  postProfileMissing: (data) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/profile-missing`,
      "post",
      null,
      data,
      null
    ),
  putProfileMissing: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/profile-missing/${id}`,
      "put",
      null,
      data,
      null
    ),
  extend: (data) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/extend`,
      "post",
      null,
      data,
      null
    ),
  detail: () =>
    fetch(`${config.BACKEND_HOST_NOTIFICATION_SERVICE}/`, "get", null, {}),
  getInfoMissing: () =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/get-info-missing`,
      "get",
      null,
      {}
    ),
  getFile: (filedId) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/get-file/${filedId}`,
      "get",
      null,
      {}
    ),
  caring: (type) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/caring-services?type=${type}`,
      "get",
      null,
      {}
    ),
    getSecretCodeAndLinkDk02: (email) =>
    fetch(
      `${config.BACKEND_HOST_NOTIFICATION_SERVICE}/get-secret-code-and-link-dk02/${email}`,
      "post",
      null,
      {}
    ),
};

export default NotificationApi;
