import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Prefixs from "./common/Prefixs";
import GatewayCompoment from "./compoment";
import Expires from "./compoment/error/Expires";
import Notfound from "./compoment/error/Notfound";
import LockCertificate from "./compoment/lockcertificate";
import NotificationExtend from "./compoment/notification/extend";
import NotificationDoc from "./compoment/notification/notenoughdoc";
import ResetPin from "./compoment/tms/resetpin";
import Extend from "./compoment/tms/service/extend";
import Register from "./compoment/tms/service/register";
import ChangeInfo from "./compoment/tms/support/changeinfo";
import ConfirmChangeInfo from "./compoment/tms/support/changeinfo/ConfirmChangeInfo";
import Request from "./compoment/tms/support/request";
import Unlock from "./compoment/unlock";
import ResetPinSuccess from "./compoment/unlock/ResetPinSuccess";
import Header from "./compoment/header";
import ViewTutorial from "./compoment/header/ViewTutorial";
import GloabalConst from "./common/GloabalConst";
import ViewTutorialType from "./common/ViewTutorialType";
import { Helmet } from "react-helmet";

const loadingDemo = (
  <div
    style={{
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    Đang tải...
  </div>
);

function App() {
  useEffect(() => {
    const path = window.location.pathname;
    const container = document.querySelector("#root");
    if (path === "/tutorial" && container) {
      container.classList.add("no-flex");
    } else if (container) {
      container.classList.remove("no-flex");
    }
  }, []);
  useEffect(() => {
    localStorage.setItem(GloabalConst.VIEW_TUTORIAL, ViewTutorialType.DISPLAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Router>
      <React.Suspense fallback={loadingDemo}>
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Switch>
          <Route
            exact
            path={Prefixs.TOKEN_GATEWAY}
            render={() => (
              <>
                <Header />
                <GatewayCompoment />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.LOCK_UNPAID}
            render={() => (
              <>
                <Header />
                <LockCertificate />
              </>
            )}
          />{" "}
          <Route
            path={Prefixs.LOCK_NOT_ENOUGH_DOC}
            render={() => (
              <>
                <Header />
                <LockCertificate />
              </>
            )}
          />{" "}
          <Route
            path={Prefixs.LOCK_REVOKE}
            render={() => (
              <>
                <Header />
                <LockCertificate />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.LOCK_WRONG_PIN}
            render={() => (
              <>
                <Header />
                <LockCertificate />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.LOCK_AUTHEN}
            render={() => (
              <>
                <Header />
                <LockCertificate />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.EXPIRES}
            render={() => (
              <>
                <Expires />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.NOTFOUND}
            render={() => (
              <>
                <Header />
                <Notfound />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.RESET_PIN_AUTHENTICATED}
            render={() => (
              <>
                <Header />
                <ResetPin />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.SUPPORT_CHANGE_INFO}
            render={() => (
              <>
                <Header />
                <ChangeInfo />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.SUPPORT_CHANGE_INFO_CONFIRM}
            render={() => (
              <>
                <Header />
                <ConfirmChangeInfo />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.SUPPORT_REQUEST}
            render={() => (
              <>
                <Header />
                <Request />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.UNLOCK_SUCCSES}
            render={() => (
              <>
                <Header />
                <Unlock />
              </>
            )}
          />{" "}
          <Route
            path={Prefixs.NOTI_NOT_ENOUGH_DOC}
            render={() => (
              <>
                <Header />
                <NotificationDoc />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.NOTI_EXTEND}
            render={() => (
              <>
                <Header />
                <NotificationExtend />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.SERVICE_REGISTER}
            render={() => (
              <>
                <Header />
                <Register />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.SERVICE_EXTEND}
            render={() => (
              <>
                <Header />
                <Extend />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.RESET_PIN_SUCCSES}
            render={() => (
              <>
                <Header />
                <ResetPinSuccess />
              </>
            )}
          />{" "}
          <Route
            exact
            path={Prefixs.VIEW_FILE_TUTORIAL}
            component={ViewTutorial}
          />{" "}
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
