import { Row } from "antd";
import logo from "../../../../assets/img/logo2.png";
import "./index.css";

const Header = () => {
  return (
    <Row className="request-header" justify="center" >
      <img src={logo} className="logo" alt="logo" />
      <span level={5} className="title-noti-req">
        Danh sách yêu cầu hỗ trợ{" "}
      </span>
    </Row>
  );
};
export default Header;
