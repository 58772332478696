const FiledNameFile = {
  TC_GTPL: {
    label: "Giấy ĐKKD",
    filed: "tcGtpl",
    type: 1,
  },
  TC_CMND_NDD: {
    label: "Chứng minh dân dân người đại diện",
    filed: "tcCmndNdd",
    type: 2,
  },
  CN_TC_XACNHANTOCHUC: {
    label: "Giấy ĐKKD/Giấy ủy quyền của cá nhân thuộc tổ chức",
    filed: "cntcXacnhantochuc",
    type: 3,
  },
  CN_TC_CMND: {
    label: "Chứng minh nhân dân cá nhân thuộc tổ chức",
    filed: "cntcCmnd",
    type: 4,
  },
  CN_CMND: {
    label: "Chứng minh nhân dân cá nhân",
    filed: "cnCmnd",
    type: 5,
  }
};

const getFiledByType = (type) => {
  for (const key in FiledNameFile) {
    if (FiledNameFile[key].type === type) {
      return FiledNameFile[key].filed;
    }
  }
  return null; // Return null if type is not found
};

const getKeyByFiled = (filed) => {
  for (const key in FiledNameFile) {
    if (FiledNameFile[key].filed === filed) {
      return FiledNameFile[key].type;
    }
  }
  return null; // Return null if type is not found
};

export default FiledNameFile;

export { getFiledByType, getKeyByFiled };
