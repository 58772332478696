import { Row } from "antd";
import logo from "../../../../assets/img/logo2.png";
import { isNullOrEmpty } from "../../../../common";
import SupportStatus from "../../../../common/SupportStatus";
import "./index.css";

const Header = (props) => {
  const { serviceEntityResponse } = props;
  return (
    <>
      <Row className="req-extend-header">
        <img src={logo} className="logo" alt="logo" />
        {(isNullOrEmpty(serviceEntityResponse?.status) ||
          (serviceEntityResponse &&
            serviceEntityResponse.status !== SupportStatus.WAITING)) && (
          <>
            <span level={5} className="title-noti">
              Yêu cầu gia hạn
            </span>
            <span level={5} className="title-lv2-noti">
              Quý khách có nhu cầu gia hạn chứng thư số.
            </span>
            <span level={5} className="title-lv2-noti">
              Vui lòng điền thông tin dưới đây để được hỗ trợ.
            </span>
          </>
        )}
      </Row>
    </>
  );
};
export default Header;
