// src/services/apiService.js
import fetch from "./FetchApiUtils";
import config from "./EndpointConfig";

const ServiceApi = {
    register: (data) =>
    fetch(
      `${config.BACKEND_HOST_PACK_SERVICE}/register`,
      "post",
      null,
      data,
      null
    ),
  extend: (data) =>
    fetch(
      `${config.BACKEND_HOST_PACK_SERVICE}/extend`,
      "post",
      null,
      data,
      null
    ),
  detail: (type) => fetch(`${config.BACKEND_HOST_PACK_SERVICE}/?reason=${type}`, "get", null, {}),
  getInfoMissing: () =>
    fetch(
      `${config.BACKEND_HOST_PACK_SERVICE}/get-info-missing`,
      "get",
      null,
      {}
    ),
  caring: (type) =>
    fetch(
      `${config.BACKEND_HOST_PACK_SERVICE}/caring-services?type=${type}`,
      "get",
      null,
      {}
    ),
};

export default ServiceApi;
