import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Spin } from "antd";
import PropTypes from "prop-types";
import React, { useState } from "react";

const ModalPromotion = React.memo((props) => {
  const { link, isModalOpen, setModalOpen } =
    props;
  const [loading, setLoading] = useState(false);


  return (
    <Modal
      title=""
      open={isModalOpen}
      //   onOk={handleOk}
      onCancel={() => setModalOpen(false)}
      width="75%" // Giữ nguyên chiều rộng là 75%
      footer={null}
    >
      <Spin
        spinning={loading}
        tip="Vui lòng đợi..."
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <iframe
          src={link}
          title="inviteCyberID"
          className="w--100 hr--33"
          loading="lazy"
          width="100%"
          height="1000vh"
          allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
          allowFullScreen
        ></iframe>
      </Spin>
    </Modal>
  );
});

ModalPromotion.propTypes = {
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

ModalPromotion.defaultProps = {
  user: null,
};

export default ModalPromotion;
