const FiledNameFileChangeInfo = {
  TC_GTPL: {
    label: "File giấy tờ pháp lý",
    filed: "tcGtpl",
    type: 1,
  },
  TC_CMND_NDD: {
    label: "CMND/CCCD người đại diện (File gồm cả 2 mặt trước và sau)",
    filed: "tcCmndNdd",
    type: 2,
  },
  CN_TC_XACNHANTOCHUC: {
    label: "File xác nhận tổ chức",
    filed: "cntcXacnhantochuc",
    type: 3,
  },
  CN_TC_CMND: {
    label: "CMND/CCCD (File gồm cả 2 mặt trước và sau)",
    filed: "cnTcCmnd",
    type: 4,
  },
  CN_CMND: {
    label: "CMND/CCCD (File gồm cả 2 mặt trước và sau)",
    filed: "cnCmnd",
    type: 5,
  }
};

const getFiledByType = (type) => {
  for (const key in FiledNameFileChangeInfo) {
    if (FiledNameFileChangeInfo[key].type === type) {
      return FiledNameFileChangeInfo[key].filed;
    }
  }
  return null; // Return null if type is not found
};

const getKeyByFiled = (filed) => {
  for (const key in FiledNameFileChangeInfo) {
    if (FiledNameFileChangeInfo[key].filed === filed) {
      return FiledNameFileChangeInfo[key].type;
    }
  }
  return null; // Return null if type is not found
};

export default FiledNameFileChangeInfo;

export { getFiledByType, getKeyByFiled };
