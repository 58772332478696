import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Upload } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import TokenResetApi from "../../../apis/TokenResetApi";
import { getFormartFile, showNotification } from "../../../common";
import FiledNameFile from "../../../common/FiledNameFile";
import TypeFromLockCer from "../../../common/TypeFromLockCer";
import ModalViewFile from "./ModalViewFile";
import "./index.css";
import constants from "../../../constants";

const ProfileMissing = (props) => {
  const { data, isFinish, setFileAdditionals, fileAdditionals, setLoading } =
    props;

  const [isModalViewFileOpen, setIsModalViewFileOpen] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [formatFile, setFormatFile] = useState(null);

  const validateFileUploaded = (_, value) => {
    // Hàm kiểm tra tùy chỉnh để kiểm tra xem file đã được tải lên hay chưa
    return value && value.length > 0 && value[0].status !== "error"
      ? Promise.resolve()
      : Promise.reject("Vui lòng tải lên file đính kèm!");
  };

  const onBeforeUpload = (file, filed) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedFileTypes.includes(file.type)) {
      showNotification("error", "Vui lòng chọn file ảnh hoặc pdf");
      return false;
    }
    if (file.size > constants.MAX_SIZE_UPLOAD_FILE) {
      showNotification(
        "error",
        "Tệp quá lớn. Kích thước tối đa cho phép là 5MB"
      );
      return false;
    }
    // const reader = new FileReader();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileAdditionals((prevNameFiles) => ({
        ...prevNameFiles,
        [filed]: {
          ...prevNameFiles[filed],
          fileName: file.name,
          type: filed,
          data: reader.result.split(",")[1],
        },
      }));
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    return false;
  };

  const handleViewFile = (filed) => {
    const formartFile = getFormartFile(fileAdditionals[filed].fileName);
    getFile(fileAdditionals[filed].fileId, formartFile);
  };

  const getFile = async (fileId, formartFile) => {
    setLoading(true);
    try {
      const res = await TokenResetApi.getFile(fileId);
      console.log("res: ", res);
      if (res && res.status === 200 && res.data.data.data) {
        setBase64File(formartFile + ";base64," + res.data.data.data);
        setFormatFile(formartFile);
        setIsModalViewFileOpen(true);
      } else {
        showNotification("error", "Không tìm thấy file", "Thông báo");
      }
      setLoading(false);
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {
        <Form.Item label={<Title level={5}>Hồ sơ đính kèm</Title>}>
          <span className="title-noti-req-file">
            Lưu ý: Hệ thống hỗ trợ file dạng pdf hoặc ảnh với dung lượng dưới
            5MB
          </span>{" "}
          <Row gutter={24}>
            {data?.type === TypeFromLockCer.TO_CHUC && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={
                      <>
                        {fileAdditionals[FiledNameFile.TC_GTPL.filed]?.fileId ? (
                          <>
                            Giấy ĐKKD (
                            <span
                              onClick={() =>
                                handleViewFile(FiledNameFile.TC_GTPL.filed)
                              }
                              className="view-file"
                            >
                              Xem file
                            </span>{" "}
                            )
                          </>
                        ) : (
                          "Giấy ĐKKD"
                        )}
                      </>
                    }
                    name="dkkd"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    required="true"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                  >
                    <Flex>
                      <Input
                        placeholder=""
                        value={
                          fileAdditionals[FiledNameFile.TC_GTPL.filed]?.fileName
                        }
                        disabled="false"
                      />

                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(e, FiledNameFile.TC_GTPL.filed)
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={
                      <>
                        {fileAdditionals[FiledNameFile.TC_CMND_NDD.filed]?.fileId ? (
                          <>
                            CMND/CCCD (File gồm cả 2 mặt trước và sau) (
                            <span
                              onClick={() =>
                                handleViewFile(FiledNameFile.TC_CMND_NDD.filed)
                              }
                              className="view-file"
                            >
                              Xem file
                            </span>{" "}
                            )
                          </>
                        ) : (
                          "CMND/CCCD (File gồm cả 2 mặt trước và sau)"
                        )}
                      </>
                    }
                    name="cmnd"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[FiledNameFile.TC_CMND_NDD.filed]
                            ?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(e, FiledNameFile.TC_CMND_NDD.filed)
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
            {data?.type === TypeFromLockCer.CA_NHAN_THUOC_TO_CHUC && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={
                      <>
                        {fileAdditionals[
                          FiledNameFile.CN_TC_XACNHANTOCHUC.filed
                        ]?.fileId ? (
                          <>
                            Giấy ĐKKD/Giấy ủy quyền của cá nhân thuộc tổ chức (
                            <span
                              onClick={() =>
                                handleViewFile(
                                  FiledNameFile.CN_TC_XACNHANTOCHUC.filed
                                )
                              }
                              className="view-file"
                            >
                              Xem file
                            </span>{" "}
                            )
                          </>
                        ) : (
                          "Giấy ĐKKD/Giấy ủy quyền của cá nhân thuộc tổ chức"
                        )}
                      </>
                    }
                    name="dkkd"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    required="true"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                  >
                    <Flex>
                      <Input
                        placeholder=""
                        value={
                          fileAdditionals[
                            FiledNameFile.CN_TC_XACNHANTOCHUC.filed
                          ]?.fileName
                        }
                        disabled="false"
                      />

                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFile.CN_TC_XACNHANTOCHUC.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label={
                      <>
                        {fileAdditionals[FiledNameFile.CN_TC_CMND.filed]?.fileId ? (
                          <>
                            CMND/CCCD (File gồm cả 2 mặt trước và sau) (
                            <span
                              onClick={() =>
                                handleViewFile(FiledNameFile.CN_TC_CMND.filed)
                              }
                              className="view-file"
                            >
                              Xem file
                            </span>{" "}
                            )
                          </>
                        ) : (
                          "CMND/CCCD (File gồm cả 2 mặt trước và sau)"
                        )}
                      </>
                    }
                    name="cmnd"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[FiledNameFile.CN_TC_CMND.filed]
                            ?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(e, FiledNameFile.CN_TC_CMND.filed)
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
            {data?.type === TypeFromLockCer.CA_NHAN && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={
                      <>
                        {fileAdditionals[FiledNameFile.CN_CMND.filed]?.fileId ? (
                          <>
                            CMND/CCCD (File gồm cả 2 mặt trước và sau) (
                            <span
                              onClick={() =>
                                handleViewFile(FiledNameFile.CN_CMND.filed)
                              }
                              className="view-file"
                            >
                              Xem file
                            </span>{" "}
                            )
                          </>
                        ) : (
                          "CMND/CCCD (File gồm cả 2 mặt trước và sau)"
                        )}
                      </>
                    }
                    name="cmnd"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[FiledNameFile.CN_CMND.filed]?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(e, FiledNameFile.CN_CMND.filed)
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form.Item>
      }
      {isModalViewFileOpen && (
        <ModalViewFile
          base64File={base64File}
          isModalViewFileOpen={isModalViewFileOpen}
          handleCancel={() => setIsModalViewFileOpen(false)}
          formatFile={formatFile}
        />
      )}
    </>
  );
};
export default ProfileMissing;
