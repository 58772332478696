import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SupportApi from "../../apis/SupportApi";
import { showNotification } from "../../common";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import GloabalConst from "../../common/GloabalConst";

const ViewTutorial = () => {
  const [base64File, setBase64File] = useState("");
  const [loading, setLoading] = useState("");

  useEffect(() => {
    getFile();
  }, []);

  const getFile = async () => {
    try {
      const res = await SupportApi.getFileHDSD(
        localStorage.getItem(GloabalConst.TUTORIAL_CODE)
      );
      if (res && res.status === 200 && res.data.data) {
        setBase64File("data:application/pdf" + ";base64," + res.data.data);
      } else {
        showNotification("error", "Không tìm thấy file", "Thông báo");
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      className="spinner"
    >
      <iframe
        src={`${base64File}#toolbar=0`}
        title="viewPDF"
        className="pdf-tutorial-viewer"
        loading="lazy"
        allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
        allowFullScreen
      ></iframe>
    </Spin>
  );
};

ViewTutorial.propTypes = {
  width: PropTypes.string,
};

ViewTutorial.defaultProps = {
  width: "70vw",
};

export default ViewTutorial;
