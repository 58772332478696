import Prefixs from "../../common/Prefixs";
import SupportStatus from "../../common/SupportStatus";
import "./index.css";
import logo from "../../assets/img/logo2.png";
import { Row } from "antd";
import Title from "antd/es/typography/Title";
import GloabalConst from "../../common/GloabalConst";

const Header = (props) => {
  const { url, lockTokenResponse, lastAttemptChecked } = props;
  return (
    <>
      <Row className="lock-certificate-header">
        <img src={logo} className="logo" alt="logo" />
        {lockTokenResponse &&
        (lockTokenResponse.status === SupportStatus.ACCEPT ||
          lockTokenResponse.status === SupportStatus.WAITINGUNLOCK) ? (
          <>
            {/* <span className="title-noti-unlock-succses">
              Mở khóa thành công
            </span> */}
            <Row>
              <span className="title-noti-unlock">
                Thiết bị USB Token của Quý khách đã được mở khóa.
              </span>
            </Row>
            <Row>
              <span className="title-noti-unlock">
                Quý khách vui lòng{" "}
                <span className="title-noti-unlock-hig">
                  rút Token và cắm lại
                </span>{" "}
                để tiếp tục sử dụng dịch vụ.
              </span>
            </Row>

            {url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) && (
              <>
                <Row className="body-unlock">
                  <Row>
                    <span className="title-des-unlock">
                      <span className="title-des-unlock-hig">Lưu ý</span>: Đối
                      với những khách hàng bổ sung hồ sơ Scan cần gửi bản cứng
                      về nhà cung cấp trong
                    </span>
                  </Row>
                  <Row>
                    <span className="title-des-unlock">
                      thời gian{" "}
                      <span className="title-des-unlock-hig">
                        5 ngày làm việc
                      </span>{" "}
                      để đảm bảo dịch vụ thông suốt.
                    </span>
                  </Row>
                  <Row style={{ width: "100%" }}>
                    <span className="title-des-unlock">
                      <span className="title-des-unlock-hig">
                        Địa chỉ nhận hồ sơ:{" "}
                      </span>
                    </span>
                  </Row>
                  <Row>
                    <span className="title-des-unlock">
                      Văn phòng hỗ trợ FastCA - SĐT:{" "}
                      {GloabalConst.PHONE_SUPPORT}
                    </span>
                  </Row>
                  <Row>
                    <span className="title-des-unlock">
                      Tầng 3B, Tòa nhà T6-08, Đường Tôn Quang Phiệt, Quận Bắc Từ
                      Liêm, Hà Nội
                    </span>
                  </Row>
                </Row>
              </>
            )}
          </>
        ) : (
          <>
            {lastAttemptChecked ? (
              ""
            ) : (
              <>
                {url.includes(Prefixs.LOCK_UNPAID) && (
                  <Title level={5} className="title-noti">
                    Thiết bị USB Token của Quý khách đã bị tạm khóa do chưa
                    thanh toán phí dịch vụ, Quý khách vui lòng liên hệ nhân viên
                    kinh doanh để được hỗ trợ mở khóa
                  </Title>
                )}
                {url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) && (
                  <Title level={5} className="title-noti">
                    Thiết bị USB Token của Quý khách đã bị tạm khóa do thiếu hồ
                    sơ. Quý khách vui lòng hoàn thiện các thông tin và hồ sơ còn
                    thiếu để được hỗ trợ Mở khóa.
                  </Title>
                )}
                {url.includes(Prefixs.LOCK_REVOKE) && (
                  <Title level={5} className="title-noti">
                    Thiết bị USB Token của Quý Khách bị khóa do chứng thư số đã
                    thu hồi. Liên hệ đăng ký cấp mới chứng thư số theo số điện
                    thoại{" "}
                    <span className="title-noti-unlock-hig">
                      1900.2066 – Nhánh 2.
                    </span>{" "}
                  </Title>
                )}
                {url.includes(Prefixs.LOCK_AUTHEN) && (
                  <Title level={5} className="title-noti">
                    Thiết bị USB Token đang tạm khóa để xác thực thông tin người
                    sử dụng. Quý khách vui lòng điền và gửi các thông tin dưới
                    đây để được mở khóa.
                  </Title>
                )}
                {url.includes(Prefixs.LOCK_WRONG_PIN) && (
                  <Title level={5} className="title-noti">
                    Thiết bị USB Token của Quý khách bị khóa do nhập sai mã PIN
                    quá số lần quy định. Nhập thông tin dưới đây để được hỗ trợ.
                  </Title>
                )}
              </>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default Header;
