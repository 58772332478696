import {
  LoadingOutlined,
  ReloadOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";

import { Helmet } from "react-helmet";
import Api from "../../apis/Api";
import LockCertificateApi from "../../apis/LockCertificateApi";
import unlock from "../../assets/img/unlock.png";
import {
  disableViewTutorial,
  getParamFromUrl,
  isNullOrEmpty,
  showNotification,
} from "../../common";
import FiledNameFromLockCer, {
  getFiledByType,
  getKeyByFiled,
} from "../../common/FiledNameFromLockCer";
import Prefixs from "../../common/Prefixs";
import SupportStatus from "../../common/SupportStatus";
import { getValueByPrefix } from "../../common/Titles";
import ModalPromotion from "../ModalPromotion";
import FailOtp from "../otp/FailOtp";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import ProfileMissing from "./ProfileMissing";
import Footer from "../footer";
import GloabalConst from "../../common/GloabalConst";
import { getTutorialCodeByPrefix } from "../../common/TutorialCode";

const LockCertificate = () => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [isOpenModalProm, setIsOpenModalProm] = useState(false);
  const [linkPromotion, setLinkPromotion] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [fileAdditionals, setFileAdditionals] = useState({
    [FiledNameFromLockCer.TC_GTPL.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.TC_CMND_NDD.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.TC_DK0101.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_DK0102.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
  });
  const [visbleFiles, setVisbleFiles] = useState({
    [FiledNameFromLockCer.TC_GTPL.filed]: false,
    [FiledNameFromLockCer.TC_CMND_NDD.filed]: false,
    [FiledNameFromLockCer.TC_DK0101.filed]: false,
    [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]: false,
    [FiledNameFromLockCer.CN_CMND.filed]: false,
    [FiledNameFromLockCer.CN_DK0102.filed]: false,
    [FiledNameFromLockCer.LINK_DK02.filed]: false,
    type: null,
    linkDk02: null,
  });
  const [lockTokenResponse, setLockTokenResponse] = useState([]);
  const [tokenProgramResponse, setTokenProgramResponse] = useState({});
  const [lastAttemptChecked, setLastAttemptChecked] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    localStorage.setItem(
      GloabalConst.TUTORIAL_CODE,
      getTutorialCodeByPrefix(window.location.pathname)
    );

    const urlCurrent = window.location.href;
    setUrl(urlCurrent);
    const dataParam = getParamFromUrl("data");

    if (urlCurrent.includes(Prefixs.LOCK_WRONG_PIN)) {
      if (!isNullOrEmpty(dataParam)) {
        auth(dataParam, urlCurrent);
      } else {
        showNotification("error", "Không tìm thấy thông tin", "Thông báo");
      }
    } else {
      fetchDataDetail(urlCurrent);
    }

    const popupParam = getParamFromUrl("openPopup");
    if (!isNullOrEmpty(popupParam)) {
      setIsOpenModalProm(true);
      setLinkPromotion(popupParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = async (data, urlCurrent) => {
    setLoading(true);
    Api.auth(data)
      .then((res) => {
        if (res && res.status === 200 && res.data.data) {
          sessionStorage.setItem("token", res.data.data.jwt);
          fetchDataDetail(urlCurrent);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        showNotification("error", error.response.data.message, "Thông báo");
        setLoading(false);
      });
  };

  const onFinish = async (values) => {
    setLoading(true);
    let fileRequest = [];
    for (const key in FiledNameFromLockCer) {
      if (FiledNameFromLockCer.hasOwnProperty(key)) {
        const enumItem = FiledNameFromLockCer[key];
        if (!isNullOrEmpty(fileAdditionals[enumItem.filed]?.data)) {
          getFile(fileRequest, enumItem.filed);
        }
      }
    }

    let request = {
      ...values,
      files: fileRequest,
      linkDk02: visbleFiles[FiledNameFromLockCer.LINK_DK02.filed]
        ? visbleFiles[FiledNameFromLockCer.LINK_DK02.filed]
        : null,
    };

    if (url.includes(Prefixs.LOCK_UNPAID)) {
      handleRequest(LockCertificateApi.debtSettlement, 201, request);
    } else if (url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC)) {
      isNullOrEmpty(lockTokenResponse.id)
        ? handleRequest(LockCertificateApi.postProfileMissing, 201, request)
        : handleRequest(
            LockCertificateApi.putProfileMissing,
            200,
            lockTokenResponse.id,
            request
          );
    } else if (url.includes(Prefixs.LOCK_REVOKE)) {
      handleRequest(LockCertificateApi.revoke, 201, request);
    } else if (url.includes(Prefixs.LOCK_WRONG_PIN)) {
      handleRequest(LockCertificateApi.wrongpin, 201, request);
    } else if (url.includes(Prefixs.LOCK_AUTHEN)) {
      handleRequest(LockCertificateApi.authenticateInfo, 201, request);
    }
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      if (res && res.status === status && res.data.data) {
        setIsFinish(true);
        setLockTokenResponse(res.data.data);
        if (
          url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) &&
          res.data.data.details
        ) {
          handleLockTokenDetails(res.data.data.details);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const getFile = (fileRequest, filed) => {
    fileRequest.push({
      id: fileAdditionals[filed].id,
      fileName: fileAdditionals[filed].fileName,
      type: getKeyByFiled(fileAdditionals[filed].type),
      data: fileAdditionals[filed].data,
    });
  };

  const fetchDataDetail = async (urlParam) => {
    setLoading(true);
    try {
      const res = await LockCertificateApi.detail();
      if (res && res.status === 200 && res.data.data) {
        handleDataResponse(res.data.data, url ? url : urlParam);
        handleDataOtpResponse(res.data.data, url ? url : urlParam);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const fetchDataById = async () => {
    setLoading(true);
    try {
      if (lockTokenResponse && lockTokenResponse.id) {
        const res = await LockCertificateApi.detailById(lockTokenResponse.id);
        if (res && res.status === 200 && res.data.data) {
          setLockTokenResponse(res.data.data[0]);
          setIsFinish(res.data.data[0].status === SupportStatus.WAITING);
          if (
            url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) &&
            res.data.data.details
          ) {
            handleLockTokenDetails(res.data.data[0].details);
          }
        }
      } else {
        showNotification("error", "Không tìm thấy thông tin hồ sơ", "");
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const handleDataResponse = (data, urlParam) => {
    if (data.tokenProgramDetail) {
      // Chỉ dành cho các trường hợp thiếu hồ sơ
      if (
        urlParam.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) &&
        data.tokenProgramDetail
      ) {
        const cloneVisbleFiles = {
          ...visbleFiles,
          [FiledNameFromLockCer.TC_GTPL.filed]:
            data.tokenProgramDetail.tcGtpl &&
            data.tokenProgramDetail.tcGtpl === 1,
          [FiledNameFromLockCer.TC_CMND_NDD.filed]:
            data.tokenProgramDetail.tcCmndNdd &&
            data.tokenProgramDetail.tcCmndNdd === 1,
          [FiledNameFromLockCer.TC_DK0101.filed]:
            data.tokenProgramDetail.tcDk0101 &&
            data.tokenProgramDetail.tcDk0101 === 1,
          [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]:
            data.tokenProgramDetail.cnXacnhantochuc &&
            data.tokenProgramDetail.cnXacnhantochuc === 1,
          [FiledNameFromLockCer.CN_CMND.filed]:
            data.tokenProgramDetail.cnCmnd &&
            data.tokenProgramDetail.cnCmnd === 1,
          [FiledNameFromLockCer.CN_DK0102.filed]:
            data.tokenProgramDetail.cnDk0102 &&
            data.tokenProgramDetail.cnDk0102 === 1,
          [FiledNameFromLockCer.LINK_DK02.filed]: !isNullOrEmpty(
            data.tokenProgramDetail.linkDk02F
          ),
          type: data.tokenProgramDetail.type,
          linkDk02: data.tokenProgramDetail.linkDk02,
        };
        setVisbleFiles(cloneVisbleFiles);
      }
      // Chỉ dành cho các trường hợp thiếu hồ sơ -end
    }
    if (data.lockTokenResponses && data.lockTokenResponses.length > 0) {
      const lockTokenResponse = data.lockTokenResponses[0];
      if (lockTokenResponse.details && lockTokenResponse.details.length > 0) {
        handleLockTokenDetails(lockTokenResponse.details);
      }
      form.setFieldsValue({
        name: lockTokenResponse.name,
        phone: lockTokenResponse.phone,
        email: lockTokenResponse.email,
      });
      setLockTokenResponse(lockTokenResponse);

      if (
        lockTokenResponse.status === SupportStatus.ACCEPT ||
        lockTokenResponse.status === SupportStatus.WAITINGUNLOCK
      ) {
        disableViewTutorial();
      }
      setIsFinish(lockTokenResponse.status === SupportStatus.WAITING);
    }
  };

  const handleDataOtpResponse = (data, urlParam) => {
    if (urlParam.includes(Prefixs.LOCK_AUTHEN) && data.tokenProgramResponse) {
      setTokenProgramResponse(data.tokenProgramResponse);
      if (
        !isNullOrEmpty(data.tokenProgramResponse.timeOTPRemaining) &&
        data.tokenProgramResponse.timeOTPRemaining === 0
      ) {
        setLastAttemptChecked(true);
      }
    }
  };

  const handleLockTokenDetails = (details) => {
    let newFileAdditionals = {};
    details.forEach((item) => {
      const type = getFiledByType(item.type);
      newFileAdditionals[type] = {
        id: item.id,
        fileName: item.fileName,
        type: item.type,
        data: item.data,
        fileId: item.fileId,
        fileData: item.fileData,
      };
      if (
        type === FiledNameFromLockCer.TC_GTPL.filed ||
        type === FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
      ) {
        form.setFieldsValue({
          dkkd: item.fileName,
        });
      }
      if (
        type === FiledNameFromLockCer.CN_CMND.filed ||
        type === FiledNameFromLockCer.TC_CMND_NDD.filed
      ) {
        form.setFieldsValue({
          cmnd: item.fileName,
        });
      }
      if (
        type === FiledNameFromLockCer.CN_DK0102.filed ||
        type === FiledNameFromLockCer.TC_DK0101.filed
      ) {
        form.setFieldsValue({
          dk01: item.fileName,
        });
      }
    });

    setFileAdditionals(newFileAdditionals);
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{getValueByPrefix(window.location.pathname)}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="lock-certificate-container"
      >
        <Header
          url={url}
          lockTokenResponse={lockTokenResponse}
          tokenProgramResponse={tokenProgramResponse}
          lastAttemptChecked={lastAttemptChecked}
        ></Header>
        {lockTokenResponse &&
        (lockTokenResponse.status === SupportStatus.ACCEPT ||
          lockTokenResponse.status === SupportStatus.WAITINGUNLOCK) ? (
          <Row className="lock-certificate-body">
            <img src={unlock} className="unlock-img" alt="unlock-img" />
          </Row>
        ) : (
          <>
            {lastAttemptChecked ? (
              <FailOtp />
            ) : (
              <Form
                onFinish={onFinish}
                layout="vertical"
                className="lock-certificate-form"
                form={form}
              >
                {lockTokenResponse.status === SupportStatus.REFUSE && (
                  <span className="text-is-finish">
                    Lý do Hồ sơ bổ sung bị từ chối:{" "}
                    {lockTokenResponse.reasonRefusal}
                  </span>
                )}

                <ContactInfo
                  url={url}
                  lockTokenResponse={lockTokenResponse}
                  isFinish={isFinish}
                  setLoading={setLoading}
                  form={form}
                  tokenProgramResponse={tokenProgramResponse}
                  setTokenProgramResponse={setTokenProgramResponse}
                  setLastAttemptChecked={setLastAttemptChecked}
                  lastAttemptChecked={lastAttemptChecked}
                />
                {/* Hồ sơ cần bổ sung */}
                {url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) && (
                  <ProfileMissing
                    lockTokenResponse={lockTokenResponse}
                    isFinish={isFinish}
                    visbleFiles={visbleFiles}
                    setFileAdditionals={setFileAdditionals}
                    fileAdditionals={fileAdditionals}
                    setLoading={setLoading}
                    form={form}
                  />
                )}
                {/* Buttons */}
                {isFinish ? (
                  <>
                    {(url.includes(Prefixs.LOCK_UNPAID) ||
                      url.includes(Prefixs.LOCK_REVOKE) ||
                      url.includes(Prefixs.LOCK_WRONG_PIN)) && (
                      <span className="text-is-finish">
                        Thông tin của Quý khách đã được gửi đến nhà cung cấp.
                        Vui lòng giữ liên lạc để được hỗ trợ.
                      </span>
                    )}
                    {url.includes(Prefixs.LOCK_NOT_ENOUGH_DOC) && (
                      <span className="text-is-finish">
                        Thông tin của Quý khách đã được gửi đến nhà cung cấp.
                        Vui lòng chờ nhà cung cấp kiểm tra và xác minh hồ sơ
                      </span>
                    )}
                    <Form.Item>
                      <Row justify="center">
                        <Col>
                          <Button
                            icon={<ReloadOutlined />}
                            type="primary"
                            onClick={fetchDataById}
                          >
                            Tải lại trang
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                ) : (
                  <Form.Item>
                    <Row justify="center">
                      <Col>
                        <Button
                          icon={<SendOutlined />}
                          type="primary"
                          htmlType="submit"
                        >
                          Gửi thông tin
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                )}
              </Form>
            )}
          </>
        )}

        {/* <Row className="lock-certificate-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
      {isOpenModalProm ? (
        <ModalPromotion
          setModalOpen={setIsOpenModalProm}
          isModalOpen={isOpenModalProm}
          link={linkPromotion}
        ></ModalPromotion>
      ) : (
        ""
      )}
    </Spin>
  );
};

export default LockCertificate;
