import React from 'react';
import { Result } from 'antd';

const Expires = () => {
  return (
    <Result
    status="403"
    title="400"
    subTitle={<span style={{fontWeight: 'bold', color:'#FE0101'}}>Phiên làm việc đã hết hạn. Vui lòng rút token và cắm lại.</span>}
    // extra={<Button type="primary">Back Home</Button>}
  />
  );
};

export default Expires;