import { Row } from "antd";
import logo from "../../../../assets/img/logo2.png";
import "./index.css";

const Header = (props) => {
  const { isFininsh } = props;
  return (
    <>
      <Row className="change-info-header">
        <img src={logo} className="logo" alt="logo" />
        {!isFininsh && <span level={5} className="title-noti-changeinfo">
          Điều chỉnh thông tin
        </span>}
      </Row>
    </>
  );
};
export default Header;
