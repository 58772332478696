class Prefixs {
    static LOCK_UNPAID = "/certificate/lock/unpaid";
    static LOCK_NOT_ENOUGH_DOC = "/certificate/lock/notenoughdoc";
    static LOCK_REVOKE = "/certificate/lock/revoke";
    static LOCK_AUTHEN = "/certificate/lock/autheninfo";
    static LOCK_WRONG_PIN = "/tokengw/unlock";
    static TOKEN_GATEWAY = "/tokengw/open";
    static EXPIRES = "/expires";
    static NOTFOUND = "/notfound";
    static RESET_PIN_AUTHENTICATED = "/tokengw/resetpin";
    static RESET_PIN_NOTAUTHENTICATED = "/tokengw/resetpin/notauthenticated";
    static SERVICE_REGISTER = "/tokengw/add";
    static SERVICE_EXTEND = "/tokengw/extent";
    static SUPPORT_CHANGE_INFO = "/tokengw/support/changeinfo";
    static SUPPORT_CHANGE_INFO_CONFIRM = "/tokengw/support/changeinfo/confirm";
    static SUPPORT_REQUEST = "/tokengw/support";
    static UNLOCK_SUCCSES = "/tokengw/unlock/success";
    static RESET_PIN_SUCCSES = "/tokengw/resetpin/success";
    static NOTI_NOT_ENOUGH_DOC = "/certificate/notification/notenoughdoc";
    static NOTI_EXTEND = "/certificate/notification/extend";
    static VIEW_FILE_TUTORIAL = "/tutorial"
  }
  
  export default Prefixs;
  