import { Row } from "antd";
import React from "react";
import logo from "../../assets/img/logo2.png";
import "../lockcertificate/index.css";
import "./index.css";
import Titles from "../../common/Titles";
import { Helmet } from "react-helmet";

const ResetPinSuccess = () => {
  return (
    <>
      <Helmet>
        <title>{Titles.RESET_PIN_SUCCSES.value}</title>
      </Helmet>
      <Row justify="center" align="middle" className="unlock-container">
        <Row className="unlock-header">
          <img src={logo} className="logo" alt="logo" />
          <span className="title-noti-unlock-succses">
            Reset mã pin thành công
          </span>
        </Row>
        <Row>
          <span className="title-des-unlock-succses">
            Thiết bị USB Token của Quý khách đã được Reset Pin.
          </span>
        </Row>
        <Row>
          <span className="title-des-unlock-succses">
            Quý khách có thể tiếp tục sử dụng dịch vụ.{" "}
          </span>
        </Row>
        <Row className="unlock-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row>
      </Row>
    </>
  );
};

export default ResetPinSuccess;
