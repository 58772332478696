import { Modal } from "antd";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const ModalCreateRequest = (props) => {
  const { width, url, isModalCreateReqOpen, handleCancel } = props;
  // const modalFooter = [
  //   <Button key="cancel" onClick={handleCancel}>
  //     Cancel
  //   </Button>,
  // ];
  const handleCloseModal = () => {
    setTimeout(() => {
      handleCancel();
    }, 2000);
  };
  const handleEvent = (event) => {
    event.data === 'success-helpdesk' && handleCloseModal();
  };
  useEffect(() => {
    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        width={width}
        title="Tạo yêu cầu"
        open={isModalCreateReqOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <iframe
          src={url}
          title="viewCreateRequest"
          className="w--100 hr--33"
          loading="lazy"
          width="100%"
          height="700px"
          allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
          allowFullScreen
          frameborder="0"

        ></iframe>
      </Modal>
    </>
  );
};

ModalCreateRequest.propTypes = {
  width: PropTypes.string,
};

ModalCreateRequest.defaultProps = {
  width: "70vw",
};

export default ModalCreateRequest;
