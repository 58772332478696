import React from "react";
import logo from "../../assets/img/logo.png";
import "./index.css";
import { Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import Prefixs from "../../common/Prefixs";
import GloabalConst from "../../common/GloabalConst";
import ViewTutorialType from "../../common/ViewTutorialType";

const Header = () => {
  const handleClick = async () => {
    window.open(Prefixs.VIEW_FILE_TUTORIAL);
  };
  const viewTutorial = localStorage.getItem(GloabalConst.VIEW_TUTORIAL);
  console.log('viewTutorial == ViewTutorialType.DISABLE: ', viewTutorial == ViewTutorialType.DISABLE);

  return (
    // <header className="header">
    //   <img src={logo} className="logo" alt="logo" />
    // </header>

    <>
      {viewTutorial == ViewTutorialType.DISABLE ? (
        <></>
      ) : (
        <Row
        id="datidcaithehdsd"
          justify="end"
          align="middle"
          // style={{
          //   margin: "2vh 5px",
          //   color: "#2684FF",
          //   fontSize: "16px",
          //   lineHeight: "1.5",
          //   textDecoration: "underline",
          // }}
          className="tutorial"
        >
          <span
            onClick={handleClick}
            style={{
              display: "flex", // Sử dụng Flexbox để căn giữa icon và chữ
              alignItems: "center", // Căn giữa theo trục dọc
              cursor: "pointer", // Thêm con trỏ dạng pointer khi hover
            }}
          >
            <QuestionCircleOutlined
              style={{ fontSize: "24px", marginRight: "10px" }}
            />
            Hướng dẫn sử dụng
          </span>
        </Row>
      )}
    </>
  );
};

export default Header;
