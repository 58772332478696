import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Upload } from "antd";
import Title from "antd/es/typography/Title";
import { showNotification } from "../../../../common";
import FiledNameFileChangeInfo from "../../../../common/FiledNameFileChangeInfo";
import TypeFromLockCer from "../../../../common/TypeFromLockCer";
import "./index.css";
import constants from "../../../../constants";

const ProfileMissing = (props) => {
  const {
    data,
    isFinish,
    setFileAdditionals,
    fileAdditionals,
  } = props;
  console.log("data: ", data);

  const validateFileUploaded = (_, value) => {
    // Hàm kiểm tra tùy chỉnh để kiểm tra xem file đã được tải lên hay chưa
    return value && value.length > 0 && value[0].status !== "error"
      ? Promise.resolve()
      : Promise.reject("Vui lòng tải lên file đính kèm!");
  };

  const onBeforeUpload = (file, filed) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedFileTypes.includes(file.type)) {
      showNotification("error", "Vui lòng chọn file ảnh hoặc pdf");
      return false;
    }
    if (file.size > constants.MAX_SIZE_UPLOAD_FILE) {
      showNotification(
        "error",
        "Tệp quá lớn. Kích thước tối đa cho phép là 5MB"
      );
      return false;
    }
    // const reader = new FileReader();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileAdditionals((prevNameFiles) => ({
        ...prevNameFiles,
        [filed]: {
          ...prevNameFiles[filed],
          fileName: file.name,
          type: filed,
          data: reader.result.split(",")[1],
        },
      }));
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    return false;
  };


  return (
    <>
      {
        <Form.Item label={<Title level={5}>Hồ sơ đính kèm</Title>}>
          <span className="title-noti-req-file">
            Lưu ý: Hệ thống hỗ trợ file dạng pdf hoặc ảnh với dung lượng dưới
            5MB
          </span>{" "}
          <Row gutter={24}>
            {data?.type === TypeFromLockCer.TO_CHUC && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={FiledNameFileChangeInfo.TC_CMND_NDD.label}
                    name="filePassport"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[
                            FiledNameFileChangeInfo.TC_CMND_NDD.filed
                          ]?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFileChangeInfo.TC_CMND_NDD.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={FiledNameFileChangeInfo.TC_GTPL.label}
                    name="fileLegal"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    required="true"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                  >
                    <Flex>
                      <Input
                        placeholder=""
                        value={
                          fileAdditionals[FiledNameFileChangeInfo.TC_GTPL.filed]
                            ?.fileName
                        }
                        disabled="false"
                      />

                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFileChangeInfo.TC_GTPL.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
            {data?.type === TypeFromLockCer.CA_NHAN_THUOC_TO_CHUC && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={FiledNameFileChangeInfo.CN_TC_CMND.label}
                    name="filePassport"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[
                            FiledNameFileChangeInfo.CN_TC_CMND.filed
                          ]?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFileChangeInfo.CN_TC_CMND.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={FiledNameFileChangeInfo.CN_TC_XACNHANTOCHUC.label}
                    name="fileCompany"
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    required="true"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                  >
                    <Flex>
                      <Input
                        placeholder=""
                        value={
                          fileAdditionals[
                            FiledNameFileChangeInfo.CN_TC_XACNHANTOCHUC.filed
                          ]?.fileName
                        }
                        disabled="false"
                      />

                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFileChangeInfo.CN_TC_XACNHANTOCHUC.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
            {data?.type === TypeFromLockCer.CA_NHAN && (
              <>
                <Col span={12}>
                  <Form.Item
                    label={
                      FiledNameFileChangeInfo.CN_CMND.label
                    }
                    name="filePassport"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    className="file-input"
                    rules={[
                      { validator: validateFileUploaded }, // Sử dụng custom validator
                    ]}
                    required="true"
                  >
                    <Flex>
                      <Input
                        value={
                          fileAdditionals[FiledNameFileChangeInfo.CN_CMND.filed]
                            ?.fileName
                        }
                        disabled="false"
                      />
                      <Upload
                        beforeUpload={(e) =>
                          onBeforeUpload(
                            e,
                            FiledNameFileChangeInfo.CN_CMND.filed
                          )
                        }
                        listType="text"
                        accept="image/png,image/jpeg,application/pdf"
                        maxCount={1}
                      >
                        <Button
                          className="chosse-file"
                          icon={<UploadOutlined />}
                          disabled={isFinish}
                        >
                          Chọn File
                        </Button>
                      </Upload>
                    </Flex>
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form.Item>
      }
    </>
  );
};
export default ProfileMissing;
