import { Button, Modal, Row, Spin } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import LockCertificateApi from "../../apis/LockCertificateApi";
import { showNotification } from "../../common";
import { ReloadOutlined, EditOutlined,LoadingOutlined } from "@ant-design/icons";
import constants from "../../constants";
const ModalConfirmSign = (props) => {
  const {
    width,
    isModalConfirmSignOpen,
    handleCancel,
    form,
    visbleFiles,
  } = props;
  const [countdown, setCountdown] = useState(constants.TIMEOUT_VERI_CODE);
  const [isCounting, setIsCounting] = useState(false);
  const [loading, setLoading] = useState(false);
 
  useEffect(() => {
    let interval;

    if (isCounting) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            setIsCounting(false);
            clearInterval(interval);
            return constants.TIMEOUT_VERI_CODE; // Reset to initial value after reaching 0
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isCounting]);

  useEffect(() => {
    if (isModalConfirmSignOpen) {
      getSecretCodeAndLinkDk02();
    }
  }, []);
  const getSecretCodeAndLinkDk02 = async () => {
    setLoading(true);
    try {
      const res = await LockCertificateApi.getSecretCodeAndLinkDk02(
        form.getFieldValue("email")
      );
      if (res && res.status === 200) {
        setIsCounting(true);
      } else {
        showNotification("error", "Không tìm thấy file", "Thông báo");
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };
  const signD02 = () => {
    const newTab = window.open(visbleFiles.linkDk02, "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      console.error("Failed to open new tab or window.");
    }
  };
  return (
    <>
      <Spin
        spinning={loading}
        tip="Vui lòng đợi..."
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Modal
          width={width}
          title={
            <>
              <Row className="header-modal-confirm-sign">
                <span>Mã xác thực</span>
              </Row>
            </>
          }
          open={isModalConfirmSignOpen}
          onCancel={handleCancel}
          footer={null}
          className="modal-confirm-sign"
        >
          <Row justify={"center"}>
            <span className="text-modal-confirm-sign">
              Mã xác thực đã được gửi vào Email của Quý khách.
            </span>
          </Row>
          <Row justify={"center"}>
            <span className="text-modal-confirm-sign">
              Sử dụng mã xác thực để ký DK02.
            </span>
          </Row>
          <Row justify="end" className="btn-modal-confirm-sign">
            <Button
              onClick={getSecretCodeAndLinkDk02}
              disabled={isCounting}
              icon={<ReloadOutlined />}
              className="btn-resend"
            >
              Gửi lại: ({countdown})
            </Button>
            <Button type="primary" icon={<EditOutlined />} onClick={signD02}>
              Ký DK02
            </Button>
          </Row>
        </Modal>
      </Spin>
    </>
  );
};

ModalConfirmSign.propTypes = {
  width: PropTypes.string,
};

ModalConfirmSign.defaultProps = {
  width: "554px",
};

export default ModalConfirmSign;
