import "./index.css";
import { Button, Col, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";
import Prefixs from "../../common/Prefixs";
import constants from "../../constants";
import { useEffect, useState } from "react";
import LockCertificateApi from "../../apis/LockCertificateApi";
import {
  isNullOrEmpty,
  showNotification,
  validateEmail,
  validatePhoneNumber,
} from "../../common";

const ContactInfo = (props) => {
  const {
    url,
    lockTokenResponse,
    isFinish,
    setLoading,
    form,
    tokenProgramResponse,
    setTokenProgramResponse,
    setLastAttemptChecked,
    lastAttemptChecked,
  } = props;
  const [countdown, setCountdown] = useState(constants.TIMEOUT_OTP);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let interval;

    if (isCounting) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            setIsCounting(false);
            clearInterval(interval);
            return constants.TIMEOUT_OTP; // Reset to initial value after reaching 0
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isCounting]);

  const handleGetOTP = () => {
    form
      .validateFields(['name', 'email', 'phone'])
      .then(() => {
        // if (isNullOrEmpty(form.getFieldValue("phone"))) {
        //   showNotification(
        //     "error",
        //     "Vui lòng nhập số điện thoại!",
        //     "Thông báo"
        //   );
        //   return;
        // }
        // Kiểm tra nếu đã lấy OTP tối đa 5 lần thì không cho lấy thêm
        if (!lastAttemptChecked) {
          // Nếu không đang trong quá trình đếm ngược, bắt đầu đếm ngược
          reciveOTP();
          // Tăng số lần lấy OTP lên 1
          // setOtpAttempts((prevAttempts) => prevAttempts + 1);
        } else {
          // Hiển thị thông báo hoặc thực hiện logic khi đã lấy OTP tối đa
          showNotification("error", "Bạn đã lấy OTP tối đa 5 lần", "");
        }
      })
      .catch((errorInfo) => {
        console.log("Error:", errorInfo);
      });
  };

  const reciveOTP = async () => {
    setLoading(true);
    try {
      let res;
      let message;
      if (tokenProgramResponse.receiveOtpEmail) {
        res = await LockCertificateApi.reciveOtpEmail(
          form.getFieldValue("email")
        );
        message = "Mã OTP của quý khách đã được gửi về email";
      } else {
        res = await LockCertificateApi.reciveOtp(form.getFieldValue("phone"));
        message = "Mã OTP của quý khách đã được gửi về số điện thoại";
      }
      if (res && res.status === 200) {
        let resOtp = res.data.data;
        if (tokenProgramResponse.receiveOtpEmail) {
          resOtp = {
            ...resOtp,
            isExceeded: true,
            receiveOtpEmail: true,
          };
        }
        setTokenProgramResponse(resOtp);
        showNotification("success", message, "Thông báo");
        setIsCounting(true);
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data.data &&
        error.response.data.data.isExceeded
      ) {
        setLastAttemptChecked(error.response.data.data.isExceeded);
      }
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Thông tin liên hệ */}
      <Form.Item
        label={
          <Title level={5}>
            {url.includes(Prefixs.LOCK_REVOKE)
              ? "Hoặc điền thông tin để được tư vấn, hỗ trợ"
              : "Thông tin liên hệ"}
          </Title>
        }
      >
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập họ tên đầy đủ!",
            },
          ]}
          value={lockTokenResponse.name}
        >
          <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập email đầy đủ!",
            },
            {
              validator: validateEmail,
            },
          ]}
          value={lockTokenResponse.email}
        >
          <Input placeholder="vd: nguyenvana@gmail.com" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số điện thoại đầy đủ!",
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
          value={lockTokenResponse.phone}
        >
          <Input placeholder="vd: 0346567676" disabled={isFinish} />
        </Form.Item>
        {url.includes(Prefixs.LOCK_AUTHEN) && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Mã OTP"
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập mã OTP!",
                    },
                  ]}
                >
                  <Row gutter={8}>
                    <Col flex="auto">
                      <Input placeholder="Nhập mã OTP" disabled={isFinish} />
                    </Col>
                    <Col flex="none">
                      <Button
                        className="btn-get-otp"
                        disabled={isFinish || isCounting}
                        onClick={handleGetOTP}
                      >
                        Lấy mã OTP ({countdown})
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {tokenProgramResponse &&
              !isNullOrEmpty(tokenProgramResponse.timeOTPRemaining) && (
                <Row>
                  <span className="text-is-finish">
                    Quý khách còn {tokenProgramResponse.timeOTPRemaining} lần
                    gửi xác thực thông tin. Vui lòng kiểm tra lại thông tin
                    trước khi gửi.
                  </span>
                </Row>
              )}
          </>
        )}
      </Form.Item>
    </>
  );
};
export default ContactInfo;
