import {
  LoadingOutlined,
  ReloadOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";

import NotificationApi from "../../../apis/NotificationApi";
import {
  disableViewTutorial,
  getParamFromUrl,
  getTutorialCode,
  isNullOrEmpty,
  showNotification,
} from "../../../common";
import FiledNameFromLockCer, {
  getFiledByType,
  getKeyByFiled,
} from "../../../common/FiledNameFromLockCer";
import SupportStatus from "../../../common/SupportStatus";
import ModalPromotion from "../../ModalPromotion";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import ProfileMissing from "./ProfileMissing";
import Titles from "../../../common/Titles";
import { Helmet } from "react-helmet";
import Footer from "../../footer";

const NotificationDoc = () => {
  const [loading, setLoading] = useState(false);
  const [isOpenModalProm, setIsOpenModalProm] = useState(false);
  const [linkPromotion, setLinkPromotion] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [fileAdditionals, setFileAdditionals] = useState({
    [FiledNameFromLockCer.TC_GTPL.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.TC_CMND_NDD.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.TC_DK0101.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_CMND.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
    [FiledNameFromLockCer.CN_DK0102.filed]: {
      fileName: "",
      type: "",
      data: "",
    },
  });
  const [visbleFiles, setVisbleFiles] = useState({
    [FiledNameFromLockCer.TC_GTPL.filed]: false,
    [FiledNameFromLockCer.TC_CMND_NDD.filed]: false,
    [FiledNameFromLockCer.TC_DK0101.filed]: false,
    [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]: false,
    [FiledNameFromLockCer.CN_CMND.filed]: false,
    [FiledNameFromLockCer.CN_DK0102.filed]: false,
    [FiledNameFromLockCer.LINK_DK02.filed]: false,
    type: null,
    linkDk02: null,
  });
  const [notificationResponse, setNotificationResponse] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getTutorialCode();
    fetchDataDetail();
    const popupParam = getParamFromUrl("openPopup");
    if (!isNullOrEmpty(popupParam)) {
      setIsOpenModalProm(true);
      setLinkPromotion(popupParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = async (values) => {
    setLoading(true);
    let fileRequest = [];
    for (const key in FiledNameFromLockCer) {
      if (FiledNameFromLockCer.hasOwnProperty(key)) {
        const enumItem = FiledNameFromLockCer[key];
        if (!isNullOrEmpty(fileAdditionals[enumItem.filed]?.data)) {
          getFile(fileRequest, enumItem.filed);
        }
      }
    }

    let request = {
      ...values,
      files: fileRequest,
    };

    isNullOrEmpty(notificationResponse.id)
      ? handleRequest(NotificationApi.postProfileMissing, 201, request)
      : handleRequest(
          NotificationApi.putProfileMissing,
          200,
          notificationResponse.id,
          request
        );
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      if (res && res.status === status && res.data.data) {
        setIsFinish(true);
        setNotificationResponse(res.data.data);
        if (res.data.data.details) {
          handleLockTokenDetails(res.data.data.details);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const getFile = (fileRequest, filed) => {
    fileRequest.push({
      id: fileAdditionals[filed].id,
      fileName: fileAdditionals[filed].fileName,
      type: getKeyByFiled(fileAdditionals[filed].type),
      data: fileAdditionals[filed].data,
    });
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const res = await NotificationApi.detail();
      console.log("res: ", res);
      if (res && res.status === 200 && res.data.data) {
        handleDataResponse(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const handleDataResponse = (data) => {
    if (data.tokenProgramDetail) {
      // Chỉ dành cho các trường hợp thiếu hồ sơ
      if (data.tokenProgramDetail) {
        const cloneVisbleFiles = {
          ...visbleFiles,
          [FiledNameFromLockCer.TC_GTPL.filed]:
            data.tokenProgramDetail.tcGtpl &&
            data.tokenProgramDetail.tcGtpl === 1,
          [FiledNameFromLockCer.TC_CMND_NDD.filed]:
            data.tokenProgramDetail.tcCmndNdd &&
            data.tokenProgramDetail.tcCmndNdd === 1,
          [FiledNameFromLockCer.TC_DK0101.filed]:
            data.tokenProgramDetail.tcDk0101 &&
            data.tokenProgramDetail.tcDk0101 === 1,
          [FiledNameFromLockCer.CN_XACNHANTOCHUC.filed]:
            data.tokenProgramDetail.cnXacnhantochuc &&
            data.tokenProgramDetail.cnXacnhantochuc === 1,
          [FiledNameFromLockCer.CN_CMND.filed]:
            data.tokenProgramDetail.cnCmnd &&
            data.tokenProgramDetail.cnCmnd === 1,
          [FiledNameFromLockCer.CN_DK0102.filed]:
            data.tokenProgramDetail.cnDk0102 &&
            data.tokenProgramDetail.cnDk0102 === 1,
          [FiledNameFromLockCer.LINK_DK02.filed]: !isNullOrEmpty(
            data.tokenProgramDetail.linkDk02F
          ),
          type: data.tokenProgramDetail.type,
          linkDk02: data.tokenProgramDetail.linkDk02,
          specifiedDate: data.tokenProgramDetail.specifiedDate,
        };
        setVisbleFiles(cloneVisbleFiles);
      }
      // Chỉ dành cho các trường hợp thiếu hồ sơ -end
    }
    if (data.notificationResponse) {
      if (
        data.notificationResponse.details &&
        data.notificationResponse.details.length > 0
      ) {
        handleLockTokenDetails(data.notificationResponse.details);
      }
      form.setFieldsValue({
        name: data.notificationResponse.name,
        phone: data.notificationResponse.phone,
        email: data.notificationResponse.email,
      });
      setNotificationResponse(data.notificationResponse);
      if (data.notificationResponse.status === SupportStatus.ACCEPT) {
        disableViewTutorial();
      }
      setIsFinish(data.notificationResponse.status === SupportStatus.WAITING);
    }
  };

  const handleLockTokenDetails = (details) => {
    let newFileAdditionals = {};
    details.forEach((item) => {
      const type = getFiledByType(item.type);
      newFileAdditionals[type] = {
        id: item.id,
        fileName: item.fileName,
        type: item.type,
        data: item.data,
        fileId: item.fileId,
        fileData: item.fileData,
      };
      if (
        type === FiledNameFromLockCer.TC_GTPL.filed ||
        type === FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
      ) {
        form.setFieldsValue({
          dkkd: item.fileName,
        });
      }
      if (
        type === FiledNameFromLockCer.CN_CMND.filed ||
        type === FiledNameFromLockCer.TC_CMND_NDD.filed
      ) {
        form.setFieldsValue({
          cmnd: item.fileName,
        });
      }
      if (
        type === FiledNameFromLockCer.CN_DK0102.filed ||
        type === FiledNameFromLockCer.TC_DK0101.filed
      ) {
        form.setFieldsValue({
          dk01: item.fileName,
        });
      }
    });
    setFileAdditionals(newFileAdditionals);
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.NOTI_NOT_ENOUGH_DOC.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="noti-doc-container no-scroll"
      >
        <Header
          notificationResponse={notificationResponse}
          visbleFiles={visbleFiles}
          isFinish={isFinish}
        ></Header>
        {notificationResponse &&
          notificationResponse.status !== SupportStatus.ACCEPT && (
            <Form
              onFinish={onFinish}
              layout="vertical"
              className="noti-doc-form"
              form={form}
            >
              {notificationResponse.status === SupportStatus.REFUSE && (
                <span className="text-is-finish">
                  Lý do Hồ sơ bổ sung bị từ chối:{" "}
                  {notificationResponse.reasonRefusal}
                </span>
              )}

              <ContactInfo
                notificationResponse={notificationResponse}
                isFinish={isFinish}
              />
              {/* Hồ sơ cần bổ sung */}
              <ProfileMissing
                notificationResponse={notificationResponse}
                isFinish={isFinish}
                visbleFiles={visbleFiles}
                setFileAdditionals={setFileAdditionals}
                fileAdditionals={fileAdditionals}
                setLoading={setLoading}
                form={form}
              />
              {/* Buttons */}
              {isFinish ? (
                <>
                  <Form.Item>
                    <Row justify="center">
                      <Col>
                        <Button
                          icon={<ReloadOutlined />}
                          type="primary"
                          onClick={fetchDataDetail}
                        >
                          Tải lại trang
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </>
              ) : (
                <Form.Item>
                  <Row justify="center">
                    <Col>
                      <Button
                        icon={<SendOutlined />}
                        type="primary"
                        htmlType="submit"
                      >
                        Gửi thông tin
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              )}
            </Form>
          )}

        {/* <Row className="noti-doc-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
      {isOpenModalProm ? (
        <ModalPromotion
          setModalOpen={setIsOpenModalProm}
          isModalOpen={isOpenModalProm}
          link={linkPromotion}
        ></ModalPromotion>
      ) : (
        ""
      )}
    </Spin>
  );
};

export default NotificationDoc;
