// src/services/apiService.js
import axios from 'axios';
import config from "./EndpointConfig";

const SignApi = {
  signPDF: async (data) => {
    try {
      const response = await axios.post(config.HOST_SIGN_PDF, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error signing file:', error.message);
      // Handle error, show notification, etc.
    }
  },
  checkVersion: async () => {
    try {
      const response = await axios.get(config.HOST_CHECK_VERSION_PLUGIN, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error signing file:', error.message);
      // Handle error, show notification, etc.
    }
  }
};

export default SignApi;
