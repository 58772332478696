import React from "react";
import "./index.css";
import { Row } from "antd";
import GloabalConst from "../../common/GloabalConst";

const Footer = () => {
  return (
    <Row className="footer">
      <span className="text-contact">
        Tổng đài: <span className="text-contact-hig">{GloabalConst.HOTLINE}</span> - Nhánh 1 |
        Email: <span className="text-contact-hig">{GloabalConst.MAIL_SUPPORT}</span> |
        Website:
        <span className="text-contact-hig"> {GloabalConst.WEBSITE}</span>{" "}
      </span>
    </Row>
  );
};

export default Footer;
