import Prefixs from "./Prefixs";

class TutorialCode {
  static LOCK_UNPAID = {
    prefix: Prefixs.LOCK_UNPAID,
    value: "gw-01",
  };
  static LOCK_NOT_ENOUGH_DOC = {
    prefix: Prefixs.LOCK_NOT_ENOUGH_DOC,
    value: "gw-02",
  };
  static LOCK_REVOKE = {
    prefix: Prefixs.LOCK_REVOKE,
    value: "gw-03",
  };
  static LOCK_AUTHEN = {
    prefix: Prefixs.LOCK_AUTHEN,
    value: "gw-04",
  };
  static LOCK_WRONG_PIN = {
    prefix: Prefixs.LOCK_WRONG_PIN,
    value: "gw-05",
  };
  static RESET_PIN_AUTHENTICATED = {
    prefix: Prefixs.RESET_PIN_AUTHENTICATED,
    value: "gw-07",
  };
  static RESET_PIN_NOTAUTHENTICATED = {
    prefix: Prefixs.RESET_PIN_NOTAUTHENTICATED,
    value: "gw-06",
  };
  static SERVICE_EXTEND = {
    prefix: Prefixs.SERVICE_EXTEND,
    value: "gw-11",
  };
  static SERVICE_REGISTER = {
    prefix: Prefixs.SERVICE_REGISTER,
    value: "gw-10",
  };
  static SUPPORT_CHANGE_INFO = {
    prefix: Prefixs.SUPPORT_CHANGE_INFO,
    value: "gw-12",
  };
  static SUPPORT_REQUEST = {
    prefix: Prefixs.SUPPORT_REQUEST,
    value: "Yêu cầu hỗ trợ",
  };
  static NOTI_NOT_ENOUGH_DOC = {
    prefix: Prefixs.NOTI_NOT_ENOUGH_DOC,
    value: "gw-08",
  };
  static NOTI_EXTEND = {
    prefix: Prefixs.NOTI_EXTEND,
    value: "gw-09",
  };
}
const getTutorialCodeByPrefix = (prefix) => {
  for (const key in TutorialCode) {
    if (TutorialCode[key].prefix === prefix) {
      return TutorialCode[key].value;
    }
  }
  return null; // Return null if type is not found
};

export default TutorialCode;

export { getTutorialCodeByPrefix };
