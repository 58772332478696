import { Row } from "antd";
import logo from "../../../../assets/img/logo2.png";
import "./index.css";

const Header = (props) => {
  const { isFinish } = props;
  return (
    <>
      <Row className="req-add-header">
        <img src={logo} className="logo" alt="logo" />
        {!isFinish && (
          <>
            <span level={5} className="title-noti">
              Yêu cầu đăng ký mới
            </span>
            <span level={5} className="title-lv2-noti">
              Quý khách có nhu cầu đăng ký mới chứng thư số.
            </span>
            <span level={5} className="title-lv2-noti">
              Vui lòng điền thông
              tin dưới đây để được hỗ trợ.
            </span>
          </>
        )}
      </Row>
    </>
  );
};
export default Header;
