const BACKEND_HOST_GATEWAY_SERVICE = 'https://demo.gwtm.fastca.vn/token-gateway';
const BACKEND_HOST_LOCK_SERVICE = 'https://demo.gwtm.fastca.vn/lock-service';
const BACKEND_HOST_RESET_SERVICE = 'https://demo.gwtm.fastca.vn/reset-service';
const BACKEND_HOST_PACK_SERVICE = 'https://demo.gwtm.fastca.vn/register-service';
const BACKEND_HOST_NOTIFICATION_SERVICE = 'https://demo.gwtm.fastca.vn/notification-service';
const BACKEND_HOST_SUPPORT_SERVICE = 'https://demo.gwtm.fastca.vn/support-service';
const HOST_PROMOTION_DEFAULT = 'https://fastca.vn/';
const HOST_SIGN_PDF = "http://localhost:6888/api/sign/signpdf";
const HOST_CHECK_VERSION_PLUGIN = "http://localhost:6888/api/info/version";

export default {
    BACKEND_HOST_GATEWAY_SERVICE,
    BACKEND_HOST_LOCK_SERVICE,
    HOST_PROMOTION_DEFAULT,
    BACKEND_HOST_RESET_SERVICE,
    BACKEND_HOST_NOTIFICATION_SERVICE,
    BACKEND_HOST_PACK_SERVICE,
    BACKEND_HOST_SUPPORT_SERVICE,
    HOST_SIGN_PDF,
    HOST_CHECK_VERSION_PLUGIN
};
