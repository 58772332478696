import { Row } from "antd";
import warningOtp from "../../assets/img/warning_otp.png";
import "./index.css";

const FailOtp = () => {
  return (
    <>
      <Row className="warning-otp">
        <img src={warningOtp} className="warning-otp-img" alt="warningOtp" />
      </Row>
      <span className="text-otp-is-fail">
        Xác thực thông tin không thành công, Quý khách đã nhập quá số lần xác
        thực. Vui lòng liên hệ nhà cung cấp qua số <span className="text-otp-is-fail-hig">1900.2066 – Nhánh 1</span> để được
        hỗ trợ.
      </span>
    </>
  );
};
export default FailOtp;
