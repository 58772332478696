class GloabalConst {
  static MAIL_SUPPORT = "hotro@fastca.vn";
  static PHONE_SUPPORT = "0932.280.068";
  static WEBSITE = "https://fastca.vn/";
  static HOTLINE = "1900.2066";
  static TUTORIAL_CODE = "tutorialCode";
  static VIEW_TUTORIAL = "viewTutorial";
}

export default GloabalConst;
