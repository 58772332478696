// src/services/apiService.js
import config from "./EndpointConfig";
import fetch from "./FetchApiUtils";

const Api = {
  getPrefixRouter: (data) =>
    fetch(
      `${config.BACKEND_HOST_GATEWAY_SERVICE}/router?data=${data}`,
      "get",
      null,
      {},
      2000
    ),
    auth: (data) =>
    fetch(
      `${config.BACKEND_HOST_GATEWAY_SERVICE}/auth?data=${data}`,
      "get",
      null,
      {}
    ),
};

export default Api;
