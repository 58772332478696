import { Col, Form, Input, Row, Select } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import SupportApi from "../../../../apis/SupportApi";
import {
  isNullOrEmpty,
  showNotification,
  validateEmail,
} from "../../../../common";
import IdentifierType from "../../../../common/IdentifierType";
import "./index.css";

const InfoOrg = (props) => {
  const { data, isFinish, setLoading, form } = props;
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    fetchProvince();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterOption = (inputValue, option) =>
    option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;

  const fetchProvince = async () => {
    setLoading(true);
    try {
      const res = await SupportApi.province();
      if (res && res.status === 200 && res.data.data) {
        setProvinces(res.data.data);
        const provinceCode = form.getFieldValue("provinceCode");
        if (!isNullOrEmpty(provinceCode)) {
          fetchDistrict(provinceCode);
        }
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const fetchDistrict = async (codeProvince) => {
    setLoading(true);
    try {
      const res = await SupportApi.district(codeProvince);
      if (res && res.status === 200 && res.data.data) {
        setDistricts(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const handleProvinceChange = (value) => {
    // Reset the district field
    form.setFieldsValue({ districtCode: undefined });

    // Now, call fetchDistrict if needed
    fetchDistrict(value);
  };
  return (
    <>
      {/* Thông tin liên hệ */}
      <Form.Item label={<Title level={5}>Thông tin điều chỉnh</Title>}>
        <Row gutter={18}>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Loại định danh"
              name="identityType"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại định danh!",
                },
              ]}
            >
              {/* <Select
                placeholder="Chọn loại định danh"
                options={IdentifierType.TO_CHUC}
                onChange={(value) => form.setFieldsValue({ identityType: value  })}
                disabled={isFinish}
                value={form.getFieldValue("identityType")}
              ></Select>{" "} */}
              <Select
                showSearch
                placeholder="Chọn loại định danh"
                optionFilterProp="children"
                filterOption={filterOption}
                allowClear
                disabled={isFinish}
                value={form.getFieldValue("identityType")}
              >
                {IdentifierType.TO_CHUC.map((item, idx) => (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Số định danh"
              name="code"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số định danh đầy đủ!",
                },
              ]}
              value={data?.code}
            >
              <Input placeholder="vd: 034656767622" disabled={isFinish} />
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Người đại diện"
              name="representFullName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập người đại diện đầy đủ!",
                },
              ]}
              value={data?.representFullName}
            >
              <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Tên khách hàng"
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tên khách hàng đầy đủ!",
                },
              ]}
              value={data?.fullName}
            >
              <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập email đầy đủ!",
                },
                {
                  validator: validateEmail,
                },
              ]}
              value={data?.email}
            >
              <Input
                placeholder="vd: nguyenvana@gmail.com"
                disabled={isFinish}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              name="provinceCode"
              label="Tỉnh thành"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn tỉnh thành đầy đủ!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Chọn tỉnh thành"
                optionFilterProp="children"
                filterOption={filterOption}
                onChange={handleProvinceChange}
                allowClear
                disabled={isFinish}
                // value={form.getFieldValue("provinceCode")}
              >
                {provinces &&
                  provinces.length > 0 &&
                  provinces.map((item, idx) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={18}>
          <Col span={8}>
            {" "}
            <Form.Item
              name="districtCode"
              label="Quận huyện"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn quận huyện!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Chọn quận huyện"
                optionFilterProp="children"
                filterOption={filterOption}
                allowClear
                disabled={isFinish}
              >
                {districts &&
                  districts.length > 0 &&
                  districts.map((item, idx) => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.fullName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item label="Địa chỉ" name="address" value={data?.address}>
              <Input
                placeholder="vd: xã A, huyện B, tỉnh C"
                disabled={isFinish}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
    </>
  );
};
export default InfoOrg;
