import { Form, Input } from "antd";
import Title from "antd/es/typography/Title";
import { validateEmail, validatePhoneNumber } from "../../../common";
import "./index.css";

const ContactInfo = (props) => {
  const {  notificationResponse, isFinish} = props;

  return (
    <>
      {/* Thông tin liên hệ */}
      <Form.Item label={<Title level={5}>Thông tin liên hệ</Title>}>
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập họ tên đầy đủ!",
            },
          ]}
          value={notificationResponse.name}
        >
          <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập email đầy đủ!",
            },
            {
              validator: validateEmail,
            },
          ]}
          value={notificationResponse.email}
        >
          <Input placeholder="vd: nguyenvana@gmail.com" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số điện thoại đầy đủ!",
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
          value={notificationResponse.phone}
        >
          <Input placeholder="vd: 0346567676" disabled={isFinish} />
        </Form.Item>
      </Form.Item> 
    </>
  );
};
export default ContactInfo;
