import Prefixs from "./Prefixs";

class Titles {
  static LOCK_UNPAID = {
    prefix: Prefixs.LOCK_UNPAID,
    value: "Khóa thiếu công nợ",
  };
  static LOCK_NOT_ENOUGH_DOC = {
    prefix: Prefixs.LOCK_NOT_ENOUGH_DOC,
    value: "Khóa thiếu hồ sơ",
  };
  static LOCK_REVOKE = {
    prefix: Prefixs.LOCK_REVOKE,
    value: "Khóa thu hồi chứng thư số",
  };
  static LOCK_AUTHEN = {
    prefix: Prefixs.LOCK_AUTHEN,
    value: "Khóa xác thực thông tin",
  };
  static LOCK_WRONG_PIN = {
    prefix: Prefixs.LOCK_WRONG_PIN,
    value: "Khóa nhập sai mã pin",
  };
  static RESET_PIN_AUTHENTICATED = {
    prefix: Prefixs.RESET_PIN_AUTHENTICATED,
    value: "Reset pin",
  };
  static SERVICE_EXTEND = {
    prefix: Prefixs.SERVICE_EXTEND,
    value: "Gia hạn",
  };
  static SERVICE_REGISTER = {
    prefix: Prefixs.SERVICE_REGISTER,
    value: "Đăng ký mới",
  };
  static SUPPORT_CHANGE_INFO = {
    prefix: Prefixs.SUPPORT_CHANGE_INFO,
    value: "Điều chỉnh thông tin",
  };
  static SUPPORT_REQUEST = {
    prefix: Prefixs.SUPPORT_REQUEST,
    value: "Yêu cầu hỗ trợ",
  };
  static UNLOCK_SUCCSES = {
    prefix: Prefixs.UNLOCK_SUCCSES,
    value: "Mở khóa thành công",
  };
  static RESET_PIN_SUCCSES = {
    prefix: Prefixs.RESET_PIN_SUCCSES,
    value: "Reset pin thành công",
  };
  static NOTI_NOT_ENOUGH_DOC = {
    prefix: Prefixs.NOTI_NOT_ENOUGH_DOC,
    value: "Thông báo thiếu hồ sơ",
  };
  static NOTI_EXTEND = {
    prefix: Prefixs.NOTI_EXTEND,
    value: "Thông báo gia hạn",
  };
}
const getValueByPrefix = (prefix) => {
  for (const key in Titles) {
    if (Titles[key].prefix === prefix) {
      return Titles[key].value;
    }
  }
  return null; // Return null if type is not found
};

export default Titles;

export { getValueByPrefix };
