import { Row } from "antd";
import React, { useEffect } from "react";
import "./index.css";

const ConfirmChangeInfo = (props) => {
  const { base64FileChange } = props;
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <span className="note-confirm">
          <span className="note-confim-hig">Lưu ý</span>: Quý khách cần ký file
          DC-01 để hoàn tất yêu cầu gửi điều chỉnh thông tin.
        </span>
      </Row>
      <Row>
        <span className="note-confirm">
          Để ký file cần thực hiện các bước sau:
        </span>
      </Row>
      <Row>
        <span className="step-confirm">
          1. Kiểm tra thông tin file DC-01 dưới đây
        </span>
      </Row>
      <Row>
        <span className="step-confirm">
          2. Cắm chữ ký số cần điều chỉnh thông tin
        </span>
      </Row>
      <Row>
        <span className="step-confirm">
          3. Cài FastCA plugin{" "}
          <a href={"/PluginFastca.exe"} download>
            Tại đây
          </a>{" "}
          (Nếu đã cài thì bỏ qua bước này)
        </span>
      </Row>
      <Row>
        <span className="step-confirm">4. Nhấn xác nhận ký</span>
      </Row>
      {base64FileChange && (
        <Row className="iframe-view-pdf-infochange">
          <iframe
            src={"data:application/pdf;base64," + base64FileChange}
            title="viewPDF"
            className="w--100 hr--33"
            loading="lazy"
            width="100%"
            height="1200px"
            allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
            allowFullScreen
          ></iframe>
        </Row>
      )}
    </>
  );
};

export default ConfirmChangeInfo;
