// src/services/apiService.js
import fetch from "./FetchApiUtils";
import config from "./EndpointConfig";

const LockCertificateApi = {
  debtSettlement: (data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/unpaid-debt`,
      "post",
      null,
      data,
      null
    ),
  postProfileMissing: (data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/profile-missing`,
      "post",
      null,
      data,
      null
    ),
  putProfileMissing: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/profile-missing/${id}`,
      "put",
      null,
      data,
      null
    ),
  revoke: (data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/revoke-certificate`,
      "post",
      null,
      data,
      null
    ),
  wrongpin: (data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/wrong-pin`,
      "post",
      null,
      data,
      null
    ),
  authenticateInfo: (data) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/authenticate-info`,
      "post",
      null,
      data,
      null
    ),
  findByStatus: (status) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/find-by-status?status=${status}`,
      "get",
      null,
      {}
    ),
  detail: () => fetch(`${config.BACKEND_HOST_LOCK_SERVICE}/`, "get", null, {}),
  detailById: (id) =>
    fetch(`${config.BACKEND_HOST_LOCK_SERVICE}/${id}`, "get", null, {}),
  getInfoMissing: () =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/get-info-missing`,
      "get",
      null,
      {}
    ),
  reciveOtp: (phone) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/receive-otp/${phone}`,
      "get",
      null,
      {}
    ),
  reciveOtpEmail: (email) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/receive-otp-email/${email}`,
      "get",
      null,
      {}
    ),
  getFile: (filedId) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/get-file/${filedId}`,
      "get",
      null,
      {}
    ),
    generateDk01: () =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/generate-dk01`,
      "get",
      null,
      {}
    ),
  getSecretCodeAndLinkDk02: (email) =>
    fetch(
      `${config.BACKEND_HOST_LOCK_SERVICE}/get-secret-code-and-link-dk02/${email}`,
      "post",
      null,
      {}
    ),
};

export default LockCertificateApi;
