import { Col, Form, Input, Row } from "antd";
import Title from "antd/es/typography/Title";
import { validateEmail, validatePhoneNumber } from "../../../../common";
import ProfileMissing from "./ProfileMissing";
import "./index.css";

const ContactInfo = (props) => {
  const {
    data,
    isFinish,
    setLoading,
    setFileAdditionals,
    fileAdditionals,
  } = props;

  return (
    <>
      {/* Thông tin liên hệ */}
      <Form.Item label={<Title level={5}>Thông tin liên hệ</Title>}>
        <Row gutter={18}>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Họ tên"
              name="contactName"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập họ tên đầy đủ!",
                },
              ]}
              value={data?.name}
            >
              <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Email"
              name="contactEmail"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập email đầy đủ!",
                },
                {
                  validator: validateEmail,
                },
              ]}
              value={data?.email}
            >
              <Input
                placeholder="vd: nguyenvana@gmail.com"
                disabled={isFinish}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            {" "}
            <Form.Item
              label="Số điện thoại"
              name="contactMobile"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập số điện thoại đầy đủ!",
                },
                {
                  validator: validatePhoneNumber,
                },
              ]}
              value={data?.phone}
            >
              <Input placeholder="vd: 0346567676" disabled={isFinish} />
            </Form.Item>
          </Col>
        </Row>

        <ProfileMissing
          data={data}
          isFinish={isFinish}
          setFileAdditionals={setFileAdditionals}
          fileAdditionals={fileAdditionals}
          setLoading={setLoading}
        />
      </Form.Item>
    </>
  );
};
export default ContactInfo;
