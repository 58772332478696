// src/services/apiService.js
import fetch from "./FetchApiUtils";
import config from "./EndpointConfig";

const SupportApi = {
  detail: () =>
    fetch(`${config.BACKEND_HOST_SUPPORT_SERVICE}/`, "get", null, {}),
  getInfoMissing: () =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/get-info-missing`,
      "get",
      null,
      {}
    ),

  generateFileChangeInfo: (id) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/generate-file-change-info/${id}`,
      "get",
      null,
      {}
    ),

  province: () =>
    fetch(`${config.BACKEND_HOST_SUPPORT_SERVICE}/province`, "get", null, {}),

  district: (data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/district/${data}`,
      "get",
      null,
      {}
    ),
  encrypt: () =>
    fetch(`${config.BACKEND_HOST_SUPPORT_SERVICE}/encrypt`, "get", null, {}),
  person: (data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/person`,
      "post",
      null,
      data
    ),
  personBelongOrganization: (data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/person-belong-organization`,
      "post",
      null,
      data
    ),
  organization: (data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/organization`,
      "post",
      null,
      data
    ),
  uploadSignedFile: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/upload-signed-file/${id}`,
      "put",
      null,
      data
    ),
  putPerson: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/person/${id}`,
      "put",
      null,
      data
    ),
  putPersonBelongOrganization: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/person-belong-organization/${id}`,
      "put",
      null,
      data
    ),
  putOrganization: (id, data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/change-info/organization/${id}`,
      "put",
      null,
      data
    ),
  getFileHDSD: (data) =>
    fetch(
      `${config.BACKEND_HOST_SUPPORT_SERVICE}/instructions-for-use/${data}`,
      "get",
      null,
      {}
    ),
};

export default SupportApi;
