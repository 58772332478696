import { Row } from "antd";
import Title from "antd/es/typography/Title";
import logo from "../../../assets/img/logo2.png";
import SupportStatus from "../../../common/SupportStatus";
import "./index.css";
import GloabalConst from "../../../common/GloabalConst";

const Header = (props) => {
  const { notificationResponse, visbleFiles, isFinish } = props;
  return (
    <>
      <Row className="noti-doc-header">
        <img src={logo} className="logo" alt="logo" />
        {notificationResponse &&
        notificationResponse.status === SupportStatus.ACCEPT ? (
          <>
            <span className="title-noti-unlock-succses">
              Cập nhật thông tin hồ sơ thành công
            </span>
            <span className="title-des-unlock">
              <span className="title-des-unlock-hig">Lưu ý</span>: Đối với những
              khách hàng bổ sung hồ sơ Scan cần gửi bản cứng về nhà cung cấp
              trong thời gian 5 ngày làm việc để đảm bảo dịch vụ thông suốt.
            </span>
            <span className="title-des-unlock">
              <span className="title-des-unlock-hig">Địa chỉ nhận hồ sơ: </span>
              Văn phòng hỗ trợ FastCA - SĐT: {GloabalConst.PHONE_SUPPORT} <br/>
              Tầng 3B, Tòa nhà T6-08,
              Đường Tôn Quang Phiệt, Quận Bắc Từ Liêm, Hà Nội
            </span>
          </>
        ) : (
          <>
            {notificationResponse?.status ? (
              <Title level={5} className="title-noti">
                Thông tin hồ sơ của Quý khách chưa hợp lệ cần bổ sung. Quý khách
                vui lòng hoàn thiện các thông tin và hồ sơ còn thiếu.
              </Title>
            ) : (
              <>
                {!isFinish ? (
                  <Title level={5} className="title-noti">
                    Thông tin hồ sơ của Quý khách chưa hợp lệ cần bổ sung. Quý
                    khách vui lòng hoàn thiện thông tin và hồ sơ còn thiếu trước
                    ngày{" "}
                    <span className="title-noti-unlock-hig">
                      {visbleFiles?.specifiedDate
                        ? visbleFiles.specifiedDate
                        : "[Ngày theo quy định]"}
                    </span>{" "}
                    để tránh gián đoạn sử dụng dịch vụ.
                  </Title>
                ) : (
                  <>
                    <span style={{fontSize: '16px'}} className="text-is-finish">
                      Thông tin của Quý khách đã được gửi đến nhà cung cấp. Vui
                      lòng chờ nhà cung cấp kiểm tra và xác minh hồ sơ.
                      Sau khi NCC xác minh sẽ gửi kết quả ở trang này hoặc gửi
                      thông tin qua email liên hệ.
                    </span>
                    <Row>
                      <span
                        className="note-is-finish"
                        style={{ fontStyle: "italic" }}
                      >
                        {" "}
                        <span className="title-des-unlock-hig">Lưu ý</span>: Đối
                        với những khách hàng bổ sung hồ sơ Scan cần gửi bản cứng
                        về nhà cung cấp trong thời gian 5 ngày làm việc, trước
                        ngày{" "}
                        <span className="title-noti-unlock-hig">
                          {visbleFiles?.specifiedDate
                            ? visbleFiles.specifiedDate
                            : "[Ngày theo quy định]"}
                        </span>{" "}
                        để đảm bảo dịch vụ thông suốt.
                      </span>
                    </Row>
                    <Row>
                      <span className="note-is-finish">
                        {" "}
                        <span className="title-des-unlock-hig">
                          Địa chỉ nhận hồ sơ:
                        </span>{" "}
                        Văn phòng hỗ trợ FastCA - SĐT: {GloabalConst.PHONE_SUPPORT}. Tầng 3B,
                        Tòa nhà T6-08, Đường Tôn Quang Phiệt, Quận Bắc Từ Liêm,
                        Hà Nội.
                      </span>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default Header;
