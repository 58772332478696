// src/services/apiService.js
import fetch from "./FetchApiUtils";
import config from "./EndpointConfig";

const TokenResetApi = {
  checkVerifitication: () =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/check-verification`,
      "get",
      null,
      {},
      null
    ),
  verification: (data) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/verification`,
      "post",
      null,
      data,
      null
    ),
  postUnVerification: (data) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/un-verification`,
      "post",
      null,
      data,
      null
    ),
  putUnVerification: (id,data) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/${id}`,
      "put",
      null,
      data,
      null
    ),
  reciveOtp: (phone) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/receive-otp/${phone}`,
      "get",
      null,
      {}
    ),
  reciveOtpEmail: (email) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/receive-otp-email/${email}`,
      "get",
      null,
      {}
    ),
  getFile: (filedId) =>
    fetch(
      `${config.BACKEND_HOST_RESET_SERVICE}/get-file/${filedId}`,
      "get",
      null,
      {}
    ),
  detail: () =>
    fetch(`${config.BACKEND_HOST_RESET_SERVICE}/`, "get", null, {}, null),
};

export default TokenResetApi;
