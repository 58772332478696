import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";

import { Helmet } from "react-helmet";
import Api from "../../../../apis/Api";
import ServiceApi from "../../../../apis/ServiceApi";
import {
  disableViewTutorial,
  getParamFromUrl,
  getTutorialCode,
  isNullOrEmpty,
  showNotification,
} from "../../../../common";
import ReasonType from "../../../../common/ReasonType";
import SupportStatus from "../../../../common/SupportStatus";
import Titles from "../../../../common/Titles";
import YearPackage from "../../../../common/YearPackage";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import Footer from "../../../footer";

const Extend = () => {
  const [loading, setLoading] = useState(false);
  const [isFinish, setIsFinish] = useState(false);
  const [serviceEntityResponse, setServiceEntityResponse] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getTutorialCode();
    const dataParam = getParamFromUrl("data");
    if (!isNullOrEmpty(dataParam)) {
      auth(dataParam);
    } else {
      showNotification("error", "Thông báo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFinish) {
      disableViewTutorial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinish]);

  const auth = async (data) => {
    setLoading(true);
    Api.auth(data)
      .then((res) => {
        if (res && res.status === 200 && res.data.data) {
          sessionStorage.setItem("token", res.data.data.jwt);
          fetchDataDetail();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        showNotification("error", error.response.data.message, "Thông báo");
        setLoading(false);
      });
  };

  const onFinish = async (values) => {
    setLoading(true);

    let request = {
      ...values,
    };

    handleRequest(ServiceApi.extend, 201, request);
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      if (res && res.status === status && res.data.data) {
        setIsFinish(true);
        setServiceEntityResponse(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const res = await ServiceApi.detail(ReasonType.EXTEND);
      console.log("res: ", res);
      if (res && res.status === 200 && res.data.data) {
        handleDataResponse(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const handleDataResponse = (data) => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        phone: data.phone,
        email: data.email,
        duration: data.duration,
      });
      setServiceEntityResponse(data);
      // setIsFinish(data.status === SupportStatus.WAITING);
    }
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.SERVICE_EXTEND.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="noti-extend-container"
      >
        <Header serviceEntityResponse={serviceEntityResponse}></Header>
        {serviceEntityResponse &&
        serviceEntityResponse.status &&
        serviceEntityResponse.status === SupportStatus.WAITING ? (
          <Row>
            {isFinish && (
              <span className="title-extend-succes">
                Yêu cầu gia hạn đã gửi thành công
              </span>
            )}
            <span level={5} className="noti-extend-succes">
              Yêu cầu gia hạn của Quý khách đã được gửi đến FastCA.
            </span>
            <span level={5} className="noti-extend-succes">
              Nhân viên kinh doanh sẽ liên hệ với Quý khách qua Email/SĐT đã
              đăng ký.
            </span>
          </Row>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            className="noti-extend-form"
            form={form}
          >
            <ContactInfo
              serviceEntityResponse={serviceEntityResponse}
              isFinish={isFinish}
            />
            {/* <Form.Item
              label="Đối tượng"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đối tượng!",
                },
              ]}
            >
              <Select
                placeholder="Chọn đối tượng"
                options={ServiceReason.filter((item) => item.value === 2)}
                onChange={(value) => form.setFieldsValue({ reason: value })}
              ></Select>
            </Form.Item> */}
            {/* <Form.Item
              label="Loại yêu cầu"
              name="objectType"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại yêu cầu!",
                },
              ]}
            >
              <Select
                placeholder="Chọn loại yêu cầu"
                options={ObjectType}
                onChange={(value) => form.setFieldsValue({ objectType: value })}
              ></Select>
            </Form.Item> */}
            <Form.Item
              label="Gói năm"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn gói năm!",
                },
              ]}
            >
              <Select
                placeholder="Chọn gói năm"
                options={YearPackage}
                onChange={(value) => form.setFieldsValue({ duration: value })}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Row justify="center">
                <Col>
                  <Button
                    icon={<SendOutlined />}
                    type="primary"
                    htmlType="submit"
                  >
                    Gửi thông tin
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}

        {/* <Row className="noti-extend-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
    </Spin>
  );
};

export default Extend;
