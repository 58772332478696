import { Row } from "antd";
import warningOtp from "../../../assets/img/warning_otp.png";
import "./index.css";

const NotFoundInfo = () => {
  return (
    <>
      <Row justify="center" className="img-notfound-info">
        <img
          src={warningOtp}
          className="warning-notfound-img"
          alt="warningOtp"
        />
      </Row>
      <Row justify="center" className="text-notfound-info">
        <span className="text-is-notfound-info">
          Không tìm thấy thông tin khách hàng.
        </span>
      </Row>
      <Row justify="center" className="text-notfound-info">
        <span className="text-is-notfound-info">
          Vui lòng liên hệ nhà cung cấp qua
          số <span className="text-is-notfound-info-hig">1900.2066 – Nhánh 1</span> để được
          hỗ trợ.
        </span>
      </Row>
    </>
  );
};
export default NotFoundInfo;
