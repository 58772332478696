import axios from "axios";
import constants from "../constants/index";

const FetchApiUtils = async (
  url,
  method,
  params,
  data,
  timeout,
  contentType = "application/json",
  responseType = "",
) => {
  const tokenFromStorage = sessionStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    "Content-Type": contentType,
  };
  if (tokenFromStorage) {
    headers.Authorization = `Bearer ${tokenFromStorage}`;
  }
  const axiosSetup = {
    url,
    method,
    headers,
    params,
    data,
    timeout: timeout ?? constants.TIMEOUT_REQUEST,
    dataType: "json",
    responseType: responseType,
  };
  return axios(axiosSetup);
};

export default FetchApiUtils;
