import { Row } from "antd";
import Title from "antd/es/typography/Title";
import logo from "../../../assets/img/logo2.png";
import { isNullOrEmpty } from "../../../common";
import "./index.css";
import SupportStatus from "../../../common/SupportStatus";

const Header = (props) => {
  const { serviceEntityResponse } = props;
  return (
    <>
      <Row className="noti-extend-header">
        <img src={logo} className="logo" alt="logo" />
        {(isNullOrEmpty(serviceEntityResponse?.status) ||
          (serviceEntityResponse &&
            serviceEntityResponse.status !== SupportStatus.WAITING)) && (
            <>
              <span level={5} className="title-noti">
                Yêu cầu gia hạn
              </span>
              <Title level={5} className="title-noti">
                Chứng thư số của Quý khách sắp hết hạn, Vui lòng điền thông tin
                dưới đây để được hỗ trợ.
              </Title>
            </>
          )}
      </Row>
    </>
  );
};
export default Header;
