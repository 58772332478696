import { Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";

const ModalViewFile = (props) => {
  const { width, base64File, isModalViewFileOpen, handleCancel, formatFile } =
    props;
  return (
    <>
      <Modal
        width={width}
        title=" Xem file"
        open={isModalViewFileOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {formatFile === "data:image/jpeg" || formatFile === "data:image/png" ? (
          <img
            alt="Image Viewer"
            src={base64File}
            width="100%"
            height="700px"
          />
        ) : (
          <iframe
            src={base64File}
            title="viewPDF"
            className="w--100 hr--33"
            loading="lazy"
            width="100%"
            height="700px"
            allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </Modal>
    </>
  );
};

ModalViewFile.propTypes = {
  width: PropTypes.string,
};

ModalViewFile.defaultProps = {
  width: "70vw",
};

export default ModalViewFile;
