import {
  DownloadOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, Col, Flex, Form, Input, Row, Upload } from "antd";
import Title from "antd/es/typography/Title";
import { useState } from "react";
import NotificationApi from "../../../apis/NotificationApi";
import {
  getFormartFile,
  isNullOrEmpty,
  showNotification,
} from "../../../common";
import FiledNameFromLockCer from "../../../common/FiledNameFromLockCer";
import TypeFromLockCer from "../../../common/TypeFromLockCer";
import ModalViewFile from "./ModalViewFile";
import "./index.css";
import ModalConfirmSign from "../../lockcertificate/ModalConfirmSign";
import LockCertificateApi from "../../../apis/LockCertificateApi";
import constants from "../../../constants";

const ProfileMissing = (props) => {
  const {
    isFinish,
    visbleFiles,
    setFileAdditionals,
    fileAdditionals,
    setLoading,
    form,
  } = props;

  const [isModalViewFileOpen, setIsModalViewFileOpen] = useState(false);
  const [isModalConfirmSignOpen, setIsModalConfirmSignOpen] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [formatFile, setFormatFile] = useState(null);

  const validateFileUploaded = (_, value) => {
    // Hàm kiểm tra tùy chỉnh để kiểm tra xem file đã được tải lên hay chưa
    return value && value.length > 0 && value[0].status !== "error"
      ? Promise.resolve()
      : Promise.reject("Vui lòng tải lên file đính kèm!");
  };

  const onBeforeUpload = (file, filed) => {
    const allowedFileTypes = ["image/png", "image/jpeg", "application/pdf"];
    if (!allowedFileTypes.includes(file.type)) {
      showNotification("error", "Vui lòng chọn file ảnh hoặc pdf");
      return false;
    }
    if (file.size > constants.MAX_SIZE_UPLOAD_FILE) {
      showNotification(
        "error",
        "Tệp quá lớn. Kích thước tối đa cho phép là 5MB"
      );
      return false;
    }
    // const reader = new FileReader();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFileAdditionals((prevNameFiles) => ({
        ...prevNameFiles,
        [filed]: {
          ...prevNameFiles[filed],
          fileName: file.name,
          type: filed,
          data: reader.result.split(",")[1],
        },
      }));
    };

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };

    return false;
  };

  const handleViewFile = (filed) => {
    const formartFile = getFormartFile(fileAdditionals[filed].fileName);
    getFile(fileAdditionals[filed].fileId, formartFile);
  };

  const getFile = async (fileId, formartFile) => {
    setLoading(true);
    try {
      const res = await NotificationApi.getFile(fileId);
      if (res && res.status === 200 && res.data.data.data) {
        setBase64File(formartFile + ";base64," + res.data.data.data);
        setFormatFile(formartFile);
        setIsModalViewFileOpen(true);
      } else {
        showNotification("error", "Không tìm thấy file", "Thông báo");
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };
  const handleOpenModalConfirmSign = () => {
    if (isNullOrEmpty(form.getFieldValue("email"))) {
      showNotification("error", "Vui lòng nhập email!", "Thông báo");
      return;
    } else {
      setIsModalConfirmSignOpen(true);
    }
  };

  const generateDk01 = async () => {
    setLoading(true);
    try {
      const res = await LockCertificateApi.generateDk01();
      if (res && res.status === 200 && res.data.data) {
        const byteCharacters = atob(res.data.data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "application/octet-stream",
        });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "FCA_DK-01_Giấy đăng ký sử dụng dịch vụ.pdf"
        ); // Set your desired file name here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } else {
        showNotification("error", "Không tìm thấy file", "Thông báo");
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {
        <Form.Item
          label={<Title level={5}>Hồ sơ chưa hợp lệ cần bổ sung</Title>}
        >
          <span className="title-noti-req-file">
            Lưu ý: Hệ thống hỗ trợ file dạng pdf hoặc ảnh với dung lượng dưới
            5MB
          </span>
          {visbleFiles.type === TypeFromLockCer.TO_CHUC ? (
            <>
              {" "}
              <Row gutter={24}>
                {visbleFiles[FiledNameFromLockCer.TC_GTPL.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[FiledNameFromLockCer.TC_GTPL.filed]
                            .fileId ? (
                            <>
                              Giấy ĐKKD (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.TC_GTPL.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "Giấy ĐKKD"
                          )}
                        </>
                      }
                      name="dkkd"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      required={true} // Fix: Use true without quotes
                      rules={[
                        { validator: validateFileUploaded },
                        // Sử dụng custom validator
                      ]}
                    >
                      <Flex>
                        <Input
                          placeholder=""
                          value={
                            fileAdditionals[FiledNameFromLockCer.TC_GTPL.filed]
                              ?.fileName
                          }
                          disabled="false"
                        />

                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.TC_GTPL.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}

                {visbleFiles[FiledNameFromLockCer.TC_CMND_NDD.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[
                            FiledNameFromLockCer.TC_CMND_NDD.filed
                          ].fileId ? (
                            <>
                              CMND/CCCD (File gồm cả 2 mặt trước và sau) (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.TC_CMND_NDD.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "CMND/CCCD (File gồm cả 2 mặt trước và sau)"
                          )}
                        </>
                      }
                      name="cmnd"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      rules={[
                        { validator: validateFileUploaded }, // Sử dụng custom validator
                      ]}
                      required="true"
                    >
                      <Flex>
                        <Input
                          value={
                            fileAdditionals[
                              FiledNameFromLockCer.TC_CMND_NDD.filed
                            ]?.fileName
                          }
                          disabled="false"
                        />
                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.TC_CMND_NDD.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={24}>
                {visbleFiles[FiledNameFromLockCer.TC_DK0101.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[FiledNameFromLockCer.TC_DK0101.filed]
                            .fileId ? (
                            <>
                              DK01 đã ký (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.TC_DK0101.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "DK01 đã ký"
                          )}{" "}
                          <Button
                            icon={<DownloadOutlined />}
                            className="btn-dow-dk01"
                            onClick={generateDk01}
                          >
                            {/* <a
                              href={
                                "/FCA_DK-01_Giấy đăng ký sử dụng dịch vụ.docx"
                              }
                              download
                            > */}
                            <i className="fas fa-download fa-lg" />
                            Tải mẫu DK01
                            {/* </a> */}
                          </Button>
                        </>
                      }
                      name="dk01"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      required="true"
                      rules={[
                        { validator: validateFileUploaded }, // Sử dụng custom validator
                      ]}
                    >
                      <Flex>
                        <Input
                          value={
                            fileAdditionals[
                              FiledNameFromLockCer.TC_DK0101.filed
                            ]?.fileName
                          }
                          disabled="false"
                        />
                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.TC_DK0101.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}

                <Col span={12} className="btn-dow-sign">
                  {/* {visbleFiles[FiledNameFromLockCer.TC_DK0101.filed] && (
                    <Button icon={<DownloadOutlined />} className="btn-dow">
                      <a
                        href={"/FCA_DK-01_Giấy đăng ký sử dụng dịch vụ.docx"}
                        download
                      >
                        <i className="fas fa-download fa-lg" />
                        Tải mẫu DK01
                      </a>
                    </Button>
                  )} */}

                  {visbleFiles[FiledNameFromLockCer.LINK_DK02.filed] && (
                    <Button
                      icon={<EditOutlined />}
                      className="btn-sign"
                      onClick={handleOpenModalConfirmSign}
                    >
                      Ký DK02
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          ) : (
            <>
              {" "}
              <Row gutter={24}>
                {visbleFiles[FiledNameFromLockCer.CN_XACNHANTOCHUC.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[
                            FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
                          ].fileId ? (
                            <>
                              Giấy ĐKKD (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "Giấy ĐKKD"
                          )}
                        </>
                      }
                      name="dkkd"
                      labelCol={{ span: 12 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      required="true"
                      rules={[
                        { validator: validateFileUploaded }, // Sử dụng custom validator
                      ]}
                    >
                      <Flex>
                        <Input
                          placeholder=""
                          value={
                            fileAdditionals[
                              FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
                            ]?.fileName
                          }
                          disabled="false"
                        />

                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.CN_XACNHANTOCHUC.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}
                {visbleFiles[FiledNameFromLockCer.CN_CMND.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[FiledNameFromLockCer.CN_CMND.filed]
                            .fileId ? (
                            <>
                              CMND/CCCD (File gồm cả 2 mặt trước và sau) (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.CN_CMND.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "CMND/CCCD (File gồm cả 2 mặt trước và sau)"
                          )}
                        </>
                      }
                      name="cmnd"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      required="true"
                      rules={[
                        { validator: validateFileUploaded }, // Sử dụng custom validator
                      ]}
                    >
                      <Flex>
                        <Input
                          value={
                            fileAdditionals[FiledNameFromLockCer.CN_CMND.filed]
                              ?.fileName
                          }
                          disabled="false"
                        />
                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.CN_CMND.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}
              </Row>
              <Row gutter={24}>
                {visbleFiles[FiledNameFromLockCer.CN_DK0102.filed] && (
                  <Col span={12}>
                    <Form.Item
                      label={
                        <>
                          {fileAdditionals[FiledNameFromLockCer.CN_DK0102.filed]
                            .fileId ? (
                            <>
                              DK01 đã ký (
                              <span
                                onClick={() =>
                                  handleViewFile(
                                    FiledNameFromLockCer.CN_DK0102.filed
                                  )
                                }
                                className="view-file"
                              >
                                Xem file
                              </span>{" "}
                              )
                            </>
                          ) : (
                            "DK01 đã ký"
                          )}{" "}
                          <Button
                            icon={<DownloadOutlined />}
                            className="btn-dow-dk01"
                            onClick={generateDk01}
                          >
                            {/* <a
                              href={
                                "/FCA_DK-01_Giấy đăng ký sử dụng dịch vụ.docx"
                              }
                              download
                            > */}
                            <i className="fas fa-download fa-lg" />
                            Tải mẫu DK01
                            {/* </a> */}
                          </Button>
                        </>
                      }
                      name="dk01"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      className="file-input"
                      required="true"
                      rules={[
                        { validator: validateFileUploaded }, // Sử dụng custom validator
                      ]}
                    >
                      <Flex>
                        <Input
                          value={
                            fileAdditionals[
                              FiledNameFromLockCer.CN_DK0102.filed
                            ]?.fileName
                          }
                          disabled="false"
                        />
                        <Upload
                          beforeUpload={(e) =>
                            onBeforeUpload(
                              e,
                              FiledNameFromLockCer.CN_DK0102.filed
                            )
                          }
                          listType="text"
                          accept="image/png,image/jpeg,application/pdf"
                          maxCount={1}
                        >
                          <Button
                            className="chosse-file"
                            icon={<UploadOutlined />}
                            disabled={isFinish}
                          >
                            Chọn File
                          </Button>
                        </Upload>
                      </Flex>
                    </Form.Item>
                  </Col>
                )}

                <Col span={12} className="btn-dow-sign">
                  {/* {visbleFiles[FiledNameFromLockCer.CN_DK0102.filed] && (
                    <Button icon={<DownloadOutlined />} className="btn-dow">
                      <a
                        href={"/FCA_DK-01_Giấy đăng ký sử dụng dịch vụ.docx"}
                        download
                      >
                        <i className="fas fa-download fa-lg" />
                        Tải mẫu DK01
                      </a>
                    </Button>
                  )} */}
                  {visbleFiles[FiledNameFromLockCer.LINK_DK02.filed] && (
                    <Button
                      icon={<EditOutlined />}
                      className="btn-sign"
                      onClick={handleOpenModalConfirmSign}
                    >
                      Ký DK02
                    </Button>
                  )}
                </Col>
              </Row>
            </>
          )}
        </Form.Item>
      }
      {isModalViewFileOpen && (
        <ModalViewFile
          base64File={base64File}
          isModalViewFileOpen={isModalViewFileOpen}
          handleCancel={() => setIsModalViewFileOpen(false)}
          formatFile={formatFile}
        />
      )}
      {isModalConfirmSignOpen && (
        <ModalConfirmSign
          isModalConfirmSignOpen={isModalConfirmSignOpen}
          handleCancel={() => setIsModalConfirmSignOpen(false)}
          form={form}
          visbleFiles={visbleFiles}
        />
      )}
    </>
  );
};
export default ProfileMissing;
