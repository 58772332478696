const FiledNameFromLockCer = {
  CN_CMND: {
    label: "Chứng minh nhân dân cá nhân",
    filed: "cnCmnd",
    type: 1,
  },
  CN_XACNHANTOCHUC: {
    label: "Xác nhận tổ chức",
    filed: "cnXacnhantochuc",
    type: 2,
  },
  CN_DK0102: {
    label: "File đăng ký DK01 cá nhân",
    filed: "cnDk0102",
    type: 3,
  },
  LINK_DK02: {
    label: "Link ký DK02",
    filed: "linkDk02",
    type: 4,
  },
  TC_GTPL: {
    label: "File giấy tờ pháp lý",
    filed: "tcGtpl",
    type: 5,
  },
  TC_CMND_NDD: {
    label: "File chứng mình dân dân người đại diện",
    filed: "tcCmndNdd",
    type: 6,
  },
  TC_DK0101: {
    label: "File đăng ký DK01 tổ chức",
    filed: "tcDk0101",
    type: 7,
  },
};

const getFiledByType = (type) => {
  for (const key in FiledNameFromLockCer) {
    if (FiledNameFromLockCer[key].type === type) {
      return FiledNameFromLockCer[key].filed;
    }
  }
  return null; // Return null if type is not found
};

const getKeyByFiled = (filed) => {
  for (const key in FiledNameFromLockCer) {
    if (FiledNameFromLockCer[key].filed === filed) {
      return FiledNameFromLockCer[key].type;
    }
  }
  return null; // Return null if type is not found
};


export default FiledNameFromLockCer;

export { getFiledByType, getKeyByFiled };

