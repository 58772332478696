import { LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import "./index.css";

import { Helmet } from "react-helmet";
import NotificationApi from "../../../apis/NotificationApi";
import {
  disableViewTutorial,
  getParamFromUrl,
  getTutorialCode,
  isNullOrEmpty,
  showNotification,
} from "../../../common";
import {} from "../../../common/FiledNameFromLockCer";
import SupportStatus from "../../../common/SupportStatus";
import Titles from "../../../common/Titles";
import YearPackage from "../../../common/YearPackage";
import ModalPromotion from "../../ModalPromotion";
import ContactInfo from "./ContactInfo";
import Header from "./Header";
import Footer from "../../footer";

const NotificationExtend = () => {
  const [loading, setLoading] = useState(false);
  const [isOpenModalProm, setIsOpenModalProm] = useState(false);
  const [linkPromotion, setLinkPromotion] = useState("");
  const [isFinish, setIsFinish] = useState(false);
  const [serviceEntityResponse, setServiceEntityResponse] = useState(null);

  const [form] = Form.useForm();

  useEffect(() => {
    getTutorialCode();
    fetchDataDetail();
    const popupParam = getParamFromUrl("openPopup");
    if (!isNullOrEmpty(popupParam)) {
      setIsOpenModalProm(true);
      setLinkPromotion(popupParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFinish) {
      disableViewTutorial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinish]);

  const onFinish = async (values) => {
    setLoading(true);

    let request = {
      ...values,
    };

    handleRequest(NotificationApi.extend, 201, request);
  };

  const handleRequest = async (apiFunction, status, ...args) => {
    try {
      const res = await apiFunction(...args);
      if (res && res.status === status && res.data.data) {
        setIsFinish(true);
        setServiceEntityResponse(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const res = await NotificationApi.detail();
      console.log("res: ", res);
      if (res && res.status === 200 && res.data.data) {
        handleDataResponse(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  const handleDataResponse = (data) => {
    if (data.serviceEntityResponse) {
      form.setFieldsValue({
        name: data.serviceEntityResponse.name,
        phone: data.serviceEntityResponse.phone,
        email: data.serviceEntityResponse.email,
      });
      setServiceEntityResponse(data.serviceEntityResponse);
      // setIsFinish(data.serviceEntityResponse.status === SupportStatus.WAITING);
    }
  };
  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.NOTI_EXTEND.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="noti-extend-container"
      >
        <Header serviceEntityResponse={serviceEntityResponse}></Header>
        {serviceEntityResponse &&
        serviceEntityResponse.status &&
        serviceEntityResponse.status === SupportStatus.WAITING ? (
          <Row>
            {isFinish && (
              <>
                {" "}
                <span className="title-extend-succes">
                  Yêu cầu gia hạn đã gửi thành công
                </span>
              </>
            )}
            <span level={5} className="noti-extend-succes">
              Yêu cầu gia hạn của Quý khách đã được gửi đến FastCA.
            </span>
            <span level={5} className="noti-extend-succes">
              Nhân viên kinh doanh sẽ liên hệ với Quý khách qua Email/SĐT đã
              đăng ký.
            </span>
          </Row>
        ) : (
          <Form
            onFinish={onFinish}
            layout="vertical"
            className="noti-extend-form"
            form={form}
          >
            <ContactInfo
              serviceEntityResponse={serviceEntityResponse}
              isFinish={isFinish}
            />
            {/* <Form.Item
              label="Đối tượng"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn đối tượng!",
                },
              ]}
            >
              <Select
                placeholder="Chọn đối tượng"
                options={ServiceReason.filter((item) => item.value === 2)}
                onChange={(value) => form.setFieldsValue({ reason: value })}
              ></Select>
            </Form.Item> */}
            {/* <Form.Item
              label="Loại yêu cầu"
              name="objectType"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn loại yêu cầu!",
                },
              ]}
            >
              <Select
                placeholder="Chọn loại yêu cầu"
                options={ObjectType}
                onChange={(value) => form.setFieldsValue({ objectType: value })}
              ></Select>
            </Form.Item> */}
            <Form.Item
              label="Gói năm"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn gói năm!",
                },
              ]}
            >
              <Select
                placeholder="Chọn gói năm"
                options={YearPackage}
                onChange={(value) => form.setFieldsValue({ duration: value })}
              ></Select>
            </Form.Item>
            <Form.Item>
              <Row justify="center">
                <Col>
                  <Button
                    icon={<SendOutlined />}
                    type="primary"
                    htmlType="submit"
                  >
                    Gửi thông tin
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        )}

        {/* <Row className="noti-extend-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
      {isOpenModalProm ? (
        <ModalPromotion
          setModalOpen={setIsOpenModalProm}
          isModalOpen={isOpenModalProm}
          link={linkPromotion}
        ></ModalPromotion>
      ) : (
        ""
      )}
    </Spin>
  );
};

export default NotificationExtend;
