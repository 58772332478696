class IdentifierType {
  static TO_CHUC = [
    { value: 1, label: "Mã số thuế" },
    { value: 2, label: "Mã ngân sách" },
    { value: 3, label: "Số quyết định" },
    { value: 4, label: "Mã bảo hiểm xã hội" },
    { value: 5, label: "Giấy phép đầu tư" },
  ];
  static CA_NHAN = [
    { value: 1, label: "CMTND,HC" },
    { value: 3, label: "CCCD" },
    { value: 4, label: "Mã BHXH" },
  ];
}

export default IdentifierType;
