import { Row } from "antd";
import logo from "../../../assets/img/logo2.png";
import ResetStatus from "../../../common/ResetStatus";
import "./index.css";

const Header = (props) => {
  const { data, lastAttemptChecked, isNotFoundInfo } = props;
  return (
    <>
      <Row className="reset-pin-header">
        <img src={logo} className="logo" alt="logo" />
        {lastAttemptChecked || isNotFoundInfo ? (
          ""
        ) : (
          <>
            {data?.status !== ResetStatus.WAIT_RESET && (
              <span level={5} className="title-noti">
                Reset mã PIN
              </span>
            )}
          </>
        )}
      </Row>
    </>
  );
};
export default Header;
