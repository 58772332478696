import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../../apis/Api";
import SupportApi from "../../../../apis/SupportApi";
import {
  getParamFromUrl,
  isNullOrEmpty,
  showNotification,
} from "../../../../common";
import Prefixs from "../../../../common/Prefixs";
import Header from "./Header";
import ModalCreateRequest from "./ModalCreateRequest";
import "./index.css";
import { Helmet } from "react-helmet";
import Titles from "../../../../common/Titles";
import Footer from "../../../footer";

const Request = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [urlEncrypt, setUrlEncrypt] = useState();
  const [isModalCreateReqOpen, setIsModalCreateReqOpen] = useState(false);
  useEffect(() => {
    const dataParam = getParamFromUrl("data");
    if (!isNullOrEmpty(dataParam)) {
      auth(dataParam);
    } else {
      showNotification("error", "Thông báo");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const auth = async (data) => {
    setLoading(true);
    Api.auth(data)
      .then((res) => {
        if (res && res.status === 200 && res.data.data) {
          sessionStorage.setItem("token", res.data.data.jwt);
          fetchDataDetail();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        showNotification("error", error.response.data.message, "Thông báo");
        setLoading(false);
      });
  };

  const fetchDataDetail = async () => {
    setLoading(true);
    try {
      const res = await SupportApi.encrypt();
      if (res && res.status === 200 && res.data.data) {
        console.log("res.data.data: ", res.data.data);
        setUrlEncrypt(res.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
      showNotification("error", error.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin
      spinning={loading}
      tip="Vui lòng đợi..."
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Helmet>
        <title>{Titles.SUPPORT_REQUEST.value}</title>
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
        className="request-container"
      >
        <Header></Header>
        <Row gutter={10} className="request-btn">
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => setIsModalCreateReqOpen(true)}
            >
              Tạo yêu cầu hỗ trợ
            </Button>
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                history.push(Prefixs.SUPPORT_CHANGE_INFO);
              }}
            >
              Tạo yêu cầu điều chỉnh thông tin
            </Button>
          </Col>
        </Row>
        {urlEncrypt && (
          <Row className="request-iframe-table">
            <iframe
              src={urlEncrypt.iframeList}
              title="inviteCyberID"
              className="w--100 hr--33"
              loading="lazy"
              width="100%"
              height="1000vh"
              allow="accelerometer autoplay encrypted-media gyroscope picture-in-picture"
              allowFullScreen
              frameborder="0"
            ></iframe>
          </Row>
        )}

        {/* <Row className="request-footer">
          <span className="text-contact">
            Tổng đài: <span className="text-contact-hig">1900.2066</span> -
            Nhánh 1| Email:{" "}
            <span className="text-contact-hig">support@fastca.vn</span> |
            Website:
            <span className="text-contact-hig"> https://fastca.vn/</span>{" "}
          </span>
        </Row> */}
        <Footer></Footer>
      </Row>
      <ModalCreateRequest
        url={urlEncrypt?.iframeCreate}
        isModalCreateReqOpen={isModalCreateReqOpen}
        handleCancel={() => setIsModalCreateReqOpen(false)}
      ></ModalCreateRequest>
    </Spin>
  );
};

export default Request;
