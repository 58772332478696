import { Form, Input } from "antd";
import { validateEmail, validatePhoneNumber } from "../../../../common";
import "./index.css";

const ContactInfo = (props) => {
  const {  data, isFinish} = props;

  return (
    <>
      {/* Thông tin liên hệ */}
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập họ tên đầy đủ!",
            },
          ]}
          value={data.name}
        >
          <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập email đầy đủ!",
            },
            {
              validator: validateEmail,
            },
          ]}
          value={data.email}
        >
          <Input placeholder="vd: nguyenvana@gmail.com" disabled={isFinish} />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số điện thoại đầy đủ!",
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
          value={data.phone}
        >
          <Input placeholder="vd: 0346567676" disabled={isFinish} />
        </Form.Item>
    </>
  );
};
export default ContactInfo;
