import { Button, Col, Form, Input, Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import TokenResetApi from "../../../apis/TokenResetApi";
import {
  isNullOrEmpty,
  showNotification,
  validateEmail,
  validatePhoneNumber,
} from "../../../common";
import constants from "../../../constants";
import ProfileMissing from "./ProfileMissing";
import "./index.css";

const ContactInfo = (props) => {
  const {
    data,
    isFinish,
    setLoading,
    form,
    tokenProgramResponse,
    setTokenProgramResponse,
    setFileAdditionals,
    fileAdditionals,
    isAuth,
    setIsAuth,
    setLastAttemptChecked,
    lastAttemptChecked,
  } = props;

  const [countdown, setCountdown] = useState(constants.TIMEOUT_OTP);
  const [isCounting, setIsCounting] = useState(false);

  useEffect(() => {
    let interval;

    if (isCounting) {
      interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 0) {
            setIsCounting(false);
            clearInterval(interval);
            return constants.TIMEOUT_OTP; // Reset to initial value after reaching 0
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isCounting]);

  // const handleGetOTP = () => {
  //   if (isNullOrEmpty(form.getFieldValue("phone"))) {
  //     showNotification("error", "Vui lòng nhập số điện thoại!", "Thông báo");
  //     return;
  //   }
  //   // Kiểm tra nếu đã lấy OTP tối đa 5 lần thì không cho lấy thêm
  //   if (!lastAttemptChecked) {
  //     // Nếu không đang trong quá trình đếm ngược, bắt đầu đếm ngược
  //     reciveOTP();
  //     // Tăng số lần lấy OTP lên 1
  //     // setOtpAttempts((prevAttempts) => prevAttempts + 1);
  //   } else {
  //     // Hiển thị thông báo hoặc thực hiện logic khi đã lấy OTP tối đa
  //     showNotification("error", "Bạn đã lấy OTP tối đa 5 lần", "");
  //   }
  // };
  const handleGetOTP = () => {
    form
      .validateFields(["name", "email", "phone", "dkkd", "cmnd"])
      .then(() => {
        if (!lastAttemptChecked) {
          // Nếu không đang trong quá trình đếm ngược, bắt đầu đếm ngược
          reciveOTP();
          // Tăng số lần lấy OTP lên 1
          // setOtpAttempts((prevAttempts) => prevAttempts + 1);
        } else {
          // Hiển thị thông báo hoặc thực hiện logic khi đã lấy OTP tối đa
          showNotification("error", "Bạn đã lấy OTP tối đa 5 lần", "");
        }
      })
      .catch((errorInfo) => {
        console.log("Error:", errorInfo);
      });
  };
  const reciveOTP = async () => {
    setLoading(true);
    try {
      let res;
      let message;
      if (tokenProgramResponse && tokenProgramResponse.receiveOtpEmail) {
        res = await TokenResetApi.reciveOtpEmail(form.getFieldValue("email"));
        message = "Mã OTP của quý khách đã được gửi về email";
      } else {
        res = await TokenResetApi.reciveOtp(form.getFieldValue("phone"));
        message = "Mã OTP của quý khách đã được gửi về số điện thoại";
      }
      if (res && res.status === 200) {
        let resOtp = res.data.data;
        if (tokenProgramResponse && tokenProgramResponse.receiveOtpEmail) {
          resOtp = {
            ...resOtp,
            isExceeded: true,
            receiveOtpEmail: true,
          };
        }
        setTokenProgramResponse(resOtp);
        showNotification("success", message, "Thông báo");
        setIsCounting(true);
      }
    } catch (error) {
      console.log("error: ", error);
      if (
        error &&
        error.response &&
        error.response.data.data &&
        error.response.data.data.isExceeded
      ) {
        setLastAttemptChecked(error.response.data.data.isExceeded);
      }
      showNotification("error", error.response.data.message, "Thông báo");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Thông tin liên hệ */}
      <Form.Item label={<Title level={5}>Thông tin liên hệ</Title>}>
        <Form.Item
          label="Họ tên"
          name="name"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập họ tên đầy đủ!",
            },
          ]}
          value={data.name}
        >
          <Input placeholder="vd: Nguyễn Văn A" disabled={isFinish || isAuth} />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập email đầy đủ!",
            },
            {
              validator: validateEmail,
            },
          ]}
          value={data.email}
        >
          <Input
            placeholder="vd: nguyenvana@gmail.com"
            disabled={isFinish || isAuth}
          />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          name="phone"
          rules={[
            {
              required: true,
              message: "Vui lòng nhập số điện thoại đầy đủ!",
            },
            {
              validator: validatePhoneNumber,
            },
          ]}
          value={data.phone}
        >
          <Input placeholder="vd: 0346567676" disabled={isFinish || isAuth} />
        </Form.Item>
        {isAuth && (
          <Row justify="end">
            <Col>
              <span
                onClick={() => {
                  setIsAuth(false);
                }}
                style={{
                  color: "#1890ff",
                  textDecoration: "underline",
                  fontWeight: "600",
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <EditOutlined style={{ marginRight: "4px" }} /> Thay đổi thông
                tin
              </span>
            </Col>
          </Row>
        )}
        {!isAuth && (
          <ProfileMissing
            data={data}
            isFinish={isFinish}
            setFileAdditionals={setFileAdditionals}
            fileAdditionals={fileAdditionals}
            setLoading={setLoading}
          />
        )}

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Mã OTP"
              name="otp"
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập mã OTP!",
                },
              ]}
            >
              <Row gutter={8}>
                <Col flex="auto">
                  <Input placeholder="Nhập mã OTP" disabled={isFinish} />
                </Col>
                <Col flex="none">
                  <Button
                    className="btn-get-otp"
                    disabled={isFinish || isCounting}
                    onClick={handleGetOTP}
                  >
                    Lấy mã OTP ({countdown})
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Col>
        </Row>
        {tokenProgramResponse &&
          !isNullOrEmpty(tokenProgramResponse.timeOTPRemaining) && (
            <Row>
              <span className="text-is-finish">
                Quý khách còn {tokenProgramResponse.timeOTPRemaining} lần gửi
                xác thực thông tin. Vui lòng kiểm tra lại thông tin trước khi
                gửi.
              </span>
            </Row>
          )}
      </Form.Item>
    </>
  );
};
export default ContactInfo;
